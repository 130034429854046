/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { dateArray } from '../_helpers';

type AccessDataProps = {
  from: string,
  to: string,
  rows:{
    facility_name: string;
    placement_id: number;
    placement_name: string;
    job_shift_id: number;
    job_shift_name: string;
    points:(string | number)[][] | [string, number, number, number, number][];
  }[]
};

export function useSetDate(source?: AccessDataProps) {
  const [dates, setDates] = useState<number[]>([]);

  useEffect(() => {
    const setTheDate = () => setDates(dateArray(source?.from, source?.to));
    setTheDate();
  }, [source]);
  return { dates };
}
