/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { MetaContractorSubcategoriesApiProps, MetaContractorSubcategoryApiProps } from './types';
import metaContractorSubcategory from './metacontractorsubcategory';

// Add Tag to the list of tags to be invalidated when the list of placements is updated
const metaContractorSubcategoryTag = baseApi.enhanceEndpoints({
  addTagTypes: [metaContractorSubcategory.tagType],
});
const metaContractorSubcategoriesApi = metaContractorSubcategoryTag.injectEndpoints({
  endpoints: (builder) => ({
    getMetaContractorSubcategories: builder.query<MetaContractorSubcategoriesApiProps, number>(
      metaContractorSubcategory.getMetaContractorSubcategories('sites'),
    ),
    getMetaContractorSubcategory: builder.query<MetaContractorSubcategoryApiProps, number>(
      metaContractorSubcategory.getEntity(),
    ),
    addMetaContractorSubcategory: builder.mutation<MetaContractorSubcategoryApiProps, Partial<MetaContractorSubcategoryApiProps>>(metaContractorSubcategory.addEntity('sites')),
    updateMetaContractorSubcategory: builder.mutation<void, Pick<MetaContractorSubcategoryApiProps, 'id'> & Partial<MetaContractorSubcategoryApiProps>>(metaContractorSubcategory.updateEntity()),
    deleteMetaContractorSubcategory: builder.mutation<{ success: boolean; id: number }, number>(
      metaContractorSubcategory.deleteEntity(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetMetaContractorSubcategoriesQuery,
  useAddMetaContractorSubcategoryMutation,
  useUpdateMetaContractorSubcategoryMutation,
  useDeleteMetaContractorSubcategoryMutation,
  useGetMetaContractorSubcategoryQuery,
} = metaContractorSubcategoriesApi;
