import BaseService from '../base';

class Upload extends BaseService {
  addFile() {
    return {
      query: <T>(body: T) => ({
        url: this.url,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }],
    };
  }
}

export default new Upload('uploads', 'Upload');
