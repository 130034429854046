/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import baseApi from '@features/base/BaseSlice';
import { CamelToSnakeCaseNested } from '@helpers/index';
import {
  AccessPerformanceParams,
  AccessesPerformanceApiProps,
  AccessParamsProps,
  AccessesApiProps,
  AccessStaffingTableParams,
  AccessesStaffingTableApiProps,
  AccessByDocumentApiProps,
  AccessByDocumentParams,
  SectionAccessParamsProps,
  AccessContractorPerformanceParams,
  AccessAreaSectionPerformanceParams,
} from './types';
import access from './access';

const accessTag = baseApi.enhanceEndpoints({ addTagTypes: [access.tagType] });
const accessApi = accessTag.injectEndpoints({
  endpoints: (builder) => ({
    getAccessPerformance: builder.query<AccessesPerformanceApiProps, AccessPerformanceParams>(
      access.getAccessPerformance(),
    ),
    getContractorAccessPerformance: builder.query<
      AccessesPerformanceApiProps,
      AccessContractorPerformanceParams
    >(
      access.getContractorAccessPerformance(),
    ),
    getAreaSectionAccessPerformance: builder.query<
      AccessesPerformanceApiProps,
      AccessAreaSectionPerformanceParams
    >(
      access.getAreaSectionAccessPerformance(),
    ),
    getAccesses: builder.query<AccessesApiProps, CamelToSnakeCaseNested<AccessParamsProps>>(
      access.getAccesses(),
    ),
    getSectionAccesses: builder.query<AccessesApiProps, SectionAccessParamsProps>(
      access.getSectionAccesses(),
    ),
    getAccessStaffingTable: builder.query<AccessesStaffingTableApiProps, AccessStaffingTableParams>(
      access.getAccessStaffingTable(),
    ),
    getAccessByDocuments: builder.query<AccessByDocumentApiProps, AccessByDocumentParams>(
      access.getAccessByDocuments(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetAccessPerformanceQuery,
  useGetAccessesQuery,
  useGetSectionAccessesQuery,
  useGetAccessStaffingTableQuery,
  useGetAccessByDocumentsQuery,
  useGetContractorAccessPerformanceQuery,
  useGetAreaSectionAccessPerformanceQuery,
} = accessApi;
