import queryString from 'query-string';
import { toSnakeCaseNested } from './toConvertCase';

const containsOnlyNumbers = (str: string) => /^(\d+,)*(\d+)$/.test(str);

export const queryToObject = (urlData: string) => {
  const query = queryString.parse(urlData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { tab, ...restOptions } = query;
  return Object.entries(restOptions).map(([key, value]) => {
    if (value === 'true') return { Field: key, Values: true };
    if (value === 'false') return { Field: key, Values: false };
    if (key !== 'rut' && containsOnlyNumbers(value as string)) return { Field: key, Values: parseInt(value as string, 10) };

    return {
      Field: key,
      Values: value,
    };
  });
};

export const queryParamsToObject = (urlData: string) => {
  if (urlData.length === 0) return urlData;
  const search = urlData.substring(1);
  return JSON.parse(
    `{"${decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`,
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queryParamsToSnakeCase = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  urlData: Record<string, any> | string,
): string => {
  const snakeCaseParams = toSnakeCaseNested(urlData);
  const urlSearchParams = new URLSearchParams(snakeCaseParams);
  return urlSearchParams.toString();
};
