/* eslint-disable class-methods-use-this */
import { providesList } from '@helpers/index';
import BaseService from '../base';
import {
  ContractorAccessTableParams,
  ContractorAccessesTableApiProps,
  ContractorParamsProps,
  ContractorSendEmailParamsProps,
  ContractorsApiProps,
  ContractorsListApiProps,
  JobParamsProps,
  JobsApiProps,
  AreaSectionContractorsListParams,
  StoreSectionContractorParams,
  ContractorsDataApiProps,
} from './types';

class Contractor extends BaseService {
  list() {
    return {
      query: ({ sectionId, ...params }: ContractorParamsProps) => ({
        url: `/sections/${sectionId}/contractors`,
        params,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: ContractorsApiProps) => (
        providesList(result?.contractors, this.tagType)
      ),
    };
  }

  metaContractors() {
    return {
      query: (siteId: number) => ({
        url: `/sites/${siteId}/meta-contractors`,
      }),
    };
  }

  contractors() {
    return {
      query: (siteId: number) => ({
        url: `/sites/${siteId}/contractors-list`,
      }),
      providesTags: (result?: ContractorsListApiProps) => providesList(result, this.tagType),
    };
  }

  getContractorJobs() {
    return {
      query: ({ contractorId, ...params }: JobParamsProps) => ({
        url: `users/current/jobs?contractor_id=${contractorId}&scheduled=active`,
        params,
      }),
      providesTags: (result?: JobsApiProps) => providesList(result?.jobs, this.tagType),
    };
  }

  sendEmail() {
    return {
      query: ({ sectionId, ...body }: ContractorSendEmailParamsProps) => ({
        url: `/sections/${sectionId}/contractors/emails`,
        method: 'POST',
        body,
      }),
    };
  }

  getContractorAccessTable() {
    return {
      query: ({ id, ...params }: ContractorAccessTableParams) => ({
        url: `contractors/${id}/stats/access`,
        params,
      }),
      providesTags: (result?: ContractorAccessesTableApiProps) => providesList(
        result?.rows,
        this.tagType,
      ),
    };
  }

  deleteStoreSectionContractor() {
    return {
      query: ({ id, sectionId }: StoreSectionContractorParams) => ({
        url: `contractors/${id}/sections/${sectionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }],
    };
  }

  linkStoreSectionContractor() {
    return {
      query: ({ id, ...params }: StoreSectionContractorParams) => ({
        url: `contractors/${id}/sections`,
        body: params,
        method: 'POST',
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }],
    };
  }

  areaSectionContractorsList() {
    return {
      query: ({ sectionId, category }: AreaSectionContractorsListParams) => ({
        url: `/sections/${sectionId}/contractors-list`,
        params: { category },
      }),
      providesTags: (result?: ContractorsDataApiProps) => providesList(result, this.tagType),
    };
  }
}

export default new Contractor('contractors', 'Contractor');
