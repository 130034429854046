/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import {
  Layout,
  Space,
  textColor,
  Typography,
  useTranslation,
} from '@lib/index';

const Footer = () => {
  const { Footer: AntdFooter } = Layout;
  const { Text } = Typography;
  const { t } = useTranslation();
  const date = new Date();
  const year = date.getFullYear();
  return (
    <AntdFooter className="text-center">
      <Space direction="vertical" size="small">
        <Text className={`font-bold ${textColor.darkGrey}`}>Ksec ©{year}</Text>
        <Text>{t('utils.footer.legalMessage')}</Text>
      </Space>
    </AntdFooter>
  );
};
export default Footer;
