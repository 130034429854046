// eslint-disable-next-line react/jsx-props-no-spreading
import React from 'react';
import { Input, FormItem } from 'formik-antd';
import { FormInputValues } from './types';

const InputTextField = ({
  name,
  label,
  placeholder,
  size,
  suffix,
  prefix,
  disabled,
  value,
  onChange,
  noStyle,
  ...rest
}: FormInputValues) => {
  const checkValue = value ? { value } : {};
  const handleOnChange = onChange ? { onChange } : {};
  return (
    <FormItem name={name} label={label} noStyle={noStyle}>
      <Input
        name={name}
        placeholder={placeholder}
        size={size}
        suffix={suffix}
        prefix={prefix}
        disabled={disabled}
        {...checkValue}
        {...handleOnChange}
        {...rest}
      />
    </FormItem>
  );
};

export default InputTextField;
