import { providesList } from '@helpers/index';
import BaseService from '../base';
import { EmployersApiProps, FacilitiesApiProps } from './types';

class Site extends BaseService {
  getEmployers() {
    return {
      query: (siteId: number) => `${this.url}/${siteId}/employers`,
      providesTags: (result: EmployersApiProps | undefined) => providesList(result, this.tagType),
    };
  }

  getFacilities() {
    return {
      query: (siteId: number) => `${this.url}/${siteId}/facilities`,
      providesTags: (result: FacilitiesApiProps | undefined) => providesList(result, this.tagType),
    };
  }
}

export default new Site('sites', 'Site');
