import React, { useState, useEffect, useRef } from 'react';
import {
  FormikHelpers,
  userService,
  toSnakeCaseNested,
  message,
  useTranslation,
  PasswordChange as PasswordChangeUtils,
  eventBus,
} from '@lib/index';
import { useAppDispatch } from '@hooks/index';
import { logOut } from '@features/account/AccountSlice';
import ReCAPTCHA from 'react-google-recaptcha';
import { changePasswordInitialValues } from '../schema/ChangePasswordDefaultValue';
import { ChangePasswordFormValues } from '../types';
import { changePasswordFormValidation } from '../schema/ChangePasswordFormValidation';

const ChangePassword = ({ requestPasswordChange }: { requestPasswordChange: boolean }) => {
  const { useUpdateUserPasswordMutation } = userService;
  const [updatePassword] = useUpdateUserPasswordMutation();
  const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(
    false,
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleCloseChangePassword = () => {
    eventBus.dispatch('logout', {});
    dispatch(logOut());
    setChangePasswordVisible(false);
  };
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const onSubmit = async (
    values: ChangePasswordFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ChangePasswordFormValues>,
  ) => {
    try {
      const captchaToken = reCaptchaRef.current?.getValue();
      if (!captchaToken) {
        message.warning(t('login.reCaptcha'), 5);
        setSubmitting(false);
        return;
      }
      await updatePassword({ user: toSnakeCaseNested(values), captcha: captchaToken }).unwrap();
      setSubmitting(false);
      resetForm();
      message.success(t('account.updateSuccess'), 2);
      setChangePasswordVisible(false);
      reCaptchaRef.current?.reset();
    } catch (err) {
      message.error(t('account.updateError'), 2);
    }
  };
  useEffect(() => {
    if (requestPasswordChange) {
      setChangePasswordVisible(true);
    }
    if (!requestPasswordChange) {
      setChangePasswordVisible(false);
    }
  }, [requestPasswordChange]);
  return (
    <PasswordChangeUtils
      modalVisibility={changePasswordVisible}
      onCancel={handleCloseChangePassword}
      onSubmit={onSubmit}
      changePasswordInitialValues={changePasswordInitialValues}
      changePasswordFormValidation={changePasswordFormValidation}
      reCaptchaRef={reCaptchaRef}
    />
  );
};

export default ChangePassword;
