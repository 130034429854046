import React from 'react';
import { Modal as AntdModal } from 'antd';
import { ModalProps } from './types';

const Modal = ({
  visible,
  closeIcon,
  onOk,
  onCancel,
  children,
  title,
  displayButtons,
  okText,
  cancelText,
  footer,
  width = 900,
  ...rest
}: ModalProps) => {
  const styleButtons = displayButtons ? '' : 'hidden';

  return (
    <AntdModal
      title={title}
      closeIcon={closeIcon}
      centered
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ className: styleButtons }}
      cancelButtonProps={{ className: styleButtons }}
      width={width}
      okText={okText}
      cancelText={cancelText}
      footer={footer}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
