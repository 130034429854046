/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import _ from 'lodash';

type AccessDataProps = {
  from: string,
  to: string,
  rows: {
    facility_name: string;
    placement_id: number;
    placement_name: string;
    job_shift_id: number;
    job_shift_name: string;
    points: (string | number)[][] | [string, number, number, number, number][];
  }[]
};

type ShiftsProps = {
  id: number,
  name: string
};
type PlacementsProps = {
  id: number,
  name: string,
  shifts: ShiftsProps[]
};

export function useSetPlacementsShifts(
  source?: AccessDataProps,
) {
  const [placements, setPlacements] = useState<PlacementsProps[]>([]);
  const [shifts, setShifts] = useState<ShiftsProps[]>([]);

  useEffect(() => {
    const loadPlacements = () => {
      const shifts: ShiftsProps[] = [];
      const placements: PlacementsProps[] = [];
      _.each(source?.rows, (row) => {
        let shift = _.find(shifts, { id: row.job_shift_id });
        let placement = _.find(placements, { id: row.placement_id });
        if (!placement) {
          placement = {
            id: row.placement_id,
            name: row.placement_name,
            shifts: [],
          };
          placements.push(placement);
        }
        if (!shift) {
          shift = {
            id: row.job_shift_id,
            name: row.job_shift_name,
          };

          shifts.push(shift);
        }

        placement.shifts.push(shift);
      });
      setPlacements(placements);
      setShifts(shifts);
    };
    loadPlacements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);
  return { placements, shifts };
}
