/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */

import baseApi from '@features/base/BaseSlice';
import {
  SectionApiProps,
  SectionContractorsApiProps,
  JobApiProps,
  JobParamsProps,
} from './types';
import section from './section';

// Add Tag to the list of tags to be invalidated when the list of jobs is updated
const sectionTag = baseApi.enhanceEndpoints({ addTagTypes: [section.tagType] });
const sectionApi = sectionTag.injectEndpoints({
  endpoints: (builder) => ({
    getSection: builder.query<SectionApiProps, number>(section.getEntity()),
    getJobContractors: builder.query<SectionContractorsApiProps, number>(
      section.getContractors(),
    ),
    addJob: builder.mutation<JobApiProps, Partial<JobParamsProps>>(section.addJob()),
  }),
  overrideExisting: false,
});

export const { useGetSectionQuery, useGetJobContractorsQuery, useAddJobMutation } = sectionApi;
