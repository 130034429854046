/* eslint-disable class-methods-use-this */
import { providesList } from '@helpers/index';
import BaseService from '../base';
import {
  CorpSiteContractorParamsProps,
  CorpSiteContractorsApiProps,
  MetaContractorApiProps,
} from './types';

class MetaContractor extends BaseService {
  updateMetaContractor() {
    return {
      query: ({ id, ...params }: MetaContractorApiProps) => ({
        url: `/meta-contractors/${id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: this.tagType }],
    };
  }

  getCorpSiteMetaContractors() {
    return {
      query: ({ id, ...params }: Partial<CorpSiteContractorParamsProps>) => ({
        url: `sites/${id}/corp-site-meta-contractors`,
        params,
      }),
      providesTags: (result?: CorpSiteContractorsApiProps) => (
        providesList(result?.meta_contractors, this.tagType)
      ),
    };
  }
}

export default new MetaContractor('meta-contractors', 'meta-contractor');
