/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import baseApi from '@features/base/BaseSlice';
import {
  RevisionCommentsApiProps, RevisionSituationsApiProps, RevisionApiProps, JobDraftParamsProps,
  RevisionDiffParams,
} from './types';
import revision from './revision';

const revisionTag = baseApi.enhanceEndpoints({ addTagTypes: [revision.tagType] });
const revisionApi = revisionTag.injectEndpoints({
  endpoints: (builder) => ({
    getRevisionSituation: builder.query<RevisionSituationsApiProps, number>(
      revision.getRevisionSituation(),
    ),
    getRevisionComment: builder.query<RevisionCommentsApiProps, number>(
      revision.getRevisionComment(),
    ),
    updateJob: builder.mutation<void, Pick<RevisionApiProps, 'id'> & { jobId: number; revision:
       { diff: Partial<RevisionApiProps['patch']> | { groups: Partial<RevisionApiProps['groups']>[]} }}>(
         revision.updateJob(),
       ),
    sendJobDraft: builder.mutation<void, JobDraftParamsProps>(
      revision.sendJobDraft(),
    ),
    deleteJobChange: builder.mutation<void, { jobId: number; id: number }>(
      revision.deleteRevision(),
    ),
    updateRevision: builder.mutation<
    void,
    Pick<RevisionDiffParams, 'id'> & Partial<RevisionDiffParams>
  >(revision.updateRevision()),
  }),
  overrideExisting: false,
});

export const {
  useGetRevisionSituationQuery,
  useGetRevisionCommentQuery,
  useUpdateJobMutation,
  useSendJobDraftMutation,
  useDeleteJobChangeMutation,
  useUpdateRevisionMutation,
} = revisionApi;
