import BaseService from '../base';

class UserReport extends BaseService {
  sendReport() {
    return {
      query: <T>(body: T) => ({
        url: this.url,
        method: 'POST',
        body,
      }),
    };
  }
}

export default new UserReport('user-reports', 'UserReport');
