import React from 'react';
import { map } from 'lodash';
import {
  Tag, Typography, List, Popover,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ContractStatusProps } from './types';

const ContractStatus = ({ contractStatus }: { contractStatus?: ContractStatusProps }) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const contractStatusData = [
    { color: 'success', status: contractStatus?.enabled, text: t('job.jobStatus.enabled') },
    { color: 'error', status: contractStatus?.rejected, text: t('job.jobStatus.rejected') },
    { color: 'warning', status: contractStatus?.iddle, text: t('job.jobStatus.pending') },
    { color: 'gray', status: contractStatus?.disabled, text: t('job.jobStatus.disabled') },
    { color: '', status: contractStatus?.none, text: t('job.jobStatus.none') },
  ];
  const contractStatusList = map(
    contractStatusData,
    (doc) => (<Tag key={doc.text} color={doc.color}>{doc.status}</Tag>),
  );
  const contractStatusPopover = (
    <List
      dataSource={contractStatusData}
      renderItem={(doc) => (
        <List.Item>
          <Tag color={doc.color}>{doc.status}</Tag>
          <Text>{doc.text}</Text>
        </List.Item>
      )}
      itemLayout="vertical"
    />
  );
  return (
    <Popover
      title={t('job.workersSummary')}
      content={contractStatusPopover}
      placement="bottom"
    >
      {contractStatusList}
    </Popover>

  );
};
ContractStatus.defaultProps = {
  contractStatus: {
    enabled: 0,
    rejected: 0,
    iddle: 0,
    disabled: 0,
    none: 0,
  },
};

export default ContractStatus;
