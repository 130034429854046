import React, { useState } from 'react';
import { AntdLayout, useAppSelector } from '@lib/index';
import { Outlet } from 'react-router-dom';
import SideBar from '../_views/pages/sidebar/SideBar';
import Header from '../_views/pages/header/Header';
import Footer from '../_views/pages/footer/Footer';
import BreadCrumb from '../_views/pages/breadcrumb/BreadCrumb';

const Layout = () => {
  const { Content } = AntdLayout;
  const { currentUser } = useAppSelector((state) => state.account);
  const isAuthenticated = currentUser?.role !== '';
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed((c) => !c);
  const sidebarClassName = 'sticky top-0 left-0 bottom-0 h-screen z-10 overflow-y-auto';
  const headerClassName = 'sticky top-0 w-full z-10 leading-none';
  const sideBar = isAuthenticated && (
    <SideBar
      triggered={collapsed}
      collapsed={collapsed}
      className={sidebarClassName}
    />
  );
  const breadCrumb = isAuthenticated && <BreadCrumb />;
  const header = isAuthenticated && (
    <Header collapsed={collapsed} toggle={toggle} className={headerClassName} />
  );
  const footer = isAuthenticated && <Footer />;
  const contentLayout = isAuthenticated ? 'my-8 mx-4 p-8 h-auto bg-white' : 'h-screen';
  return (
    <AntdLayout className="h-screen">
      {sideBar}
      <AntdLayout className="site-layout overflow-auto">
        {header}
        <Content className={contentLayout}>
          {breadCrumb}
          <Outlet />
        </Content>
        {footer}
      </AntdLayout>
    </AntdLayout>
  );
};

export default Layout;
