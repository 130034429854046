import React from 'react';
import { Badge } from 'antd';
import { statusColor } from '@helpers/index';

const SideBarNotification = ({ count }: { count: number }) => (
  (
    <Badge
      showZero
      count={count !== 0 ? count : 0}
      className="text-white text-sm w-9 mr-7"
      style={{
        backgroundColor: statusColor.orange,
      }}
    />
  )
);

export default SideBarNotification;
