/* eslint-disable class-methods-use-this */
import BaseService from '../base';

class User extends BaseService {
  updatePassword() {
    return {
      query: <T>(body: T) => ({
        url: '/auth/password',
        method: 'PATCH',
        body,
      }),
    };
  }

  updateUserPassword() {
    return {
      query: <T>(body: T) => ({
        url: '/auth/user-password',
        method: 'PATCH',
        body,
      }),
    };
  }
}

export default new User('users', 'User');
