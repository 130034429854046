import React, { useEffect } from 'react';
import {
  Col, Layout, Row, Popover,
  Button, Typography, Divider,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
  GlobalOutlined,
  useTranslation,
  useAppSelector,
  useAppDispatch,
} from '@lib/index';
import { assignLang } from '@features/account/AccountSlice';
import { HeaderProps } from './types';
import { SwitchProfile } from './switchprofile/SwitchProfile';

const Header = ({ toggle, collapsed, className }: HeaderProps) => {
  const { Text, Paragraph } = Typography;
  const { currentUser } = useAppSelector((state) => state.account);
  const AntdHeader = Layout.Header;
  const dispatch = useAppDispatch();
  const notificationContent = (
    <>
      <Paragraph>
        <Text>Notification 1</Text>
      </Paragraph>
      <Paragraph>
        <Text>Notification 2</Text>
      </Paragraph>
    </>
  );

  const { i18n } = useTranslation();
  const handleLanguageChange = (lang: string) => dispatch(assignLang(lang));

  useEffect(() => {
    i18n.changeLanguage(currentUser?.lang);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentUser?.lang]);
  const languageContent = (
    <Row justify="center">
      <Col span={8}>
        <Button shape="round" type="primary" size="small" onClick={() => handleLanguageChange('en')}>
          en
        </Button>
      </Col>
      <Divider />
      <Col span={8}>
        <Button shape="round" type="primary" size="small" onClick={() => handleLanguageChange('es')}>
          es
        </Button>
      </Col>
    </Row>
  );

  return (
    <AntdHeader className={`bg-white p-2.5 h-24 min-h-0 ${className}`}>
      <Row className="h-full">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row justify="end" align="middle" className="h-full">
            <Col xs={2} sm={2} md={12} lg={12} xl={12}>
              {collapsed ? (
                <MenuUnfoldOutlined className="trigger text-3xl" onClick={toggle} />
              ) : (
                <MenuFoldOutlined className="trigger text-3xl" onClick={toggle} />
              )}
            </Col>
            <Col xs={22} sm={22} md={12} lg={12} xl={12}>
              <Row justify="end" align="middle">
                <Col xs={16} sm={16} md={12} lg={8} xl={6}>
                  <Text className="text-xs xs:text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm 2xl:text-sm">{currentUser?.roleLabel}</Text>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  <SwitchProfile />
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Popover
                    placement="bottom"
                    content={notificationContent}
                    trigger="click"
                  >
                    <BellOutlined />
                  </Popover>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Popover
                    placement="bottom"
                    content={languageContent}
                    trigger="click"
                  >
                    <GlobalOutlined />
                  </Popover>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </AntdHeader>
  );
};

export default Header;
