/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */

import baseApi from '@features/base/BaseSlice';
import invoice from './invoice';
import {
  InvoiceStatsApiProps,
  InvoiceStatsParams,
  InvoiceWorkersApiProps,
  InvoiceWorkersParams,
  UploadInvoiceApiProps,
  UploadInvoiceParams,
} from './types';

// Add Tag to the list of tags to be invalidated when the list of attachments is updated
const invoiceTag = baseApi.enhanceEndpoints({ addTagTypes: [invoice.tagType] });
const invoiceApi = invoiceTag.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceStatsByMonth: builder.query<InvoiceStatsApiProps, InvoiceStatsParams>(
      invoice.getInvoiceStatsByMonth(),
    ),
    getInvoiceWorkers: builder.query<InvoiceWorkersApiProps, InvoiceWorkersParams>(
      invoice.getInvoiceWorkers(),
    ),
    uploadInvoice: builder.mutation<UploadInvoiceApiProps, UploadInvoiceParams>(
      invoice.uploadInvoice(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetInvoiceStatsByMonthQuery,
  useGetInvoiceWorkersQuery,
  useUploadInvoiceMutation,
} = invoiceApi;
