/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */

export const eventBus = {
  on<T>(event: any, callback: (e: { detail: T }) => void) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch<T>(event: any, data: T) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: any, callback: () => void) {
    document.removeEventListener(event, callback);
  },
};
