/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Col, Menu, Row } from 'antd';
import { MenuWithoutPermissionProps } from './types';

const MenuWithoutPermission = ({
  icon,
  handleLink,
  title,
  indicator,
  ...props
}: MenuWithoutPermissionProps) => (
  <Menu.Item icon={icon} onClick={handleLink} {...props}>
    <Row>
      <Col xs={19} sm={19} md={19} lg={19} xl={19}>
        {title}
      </Col>
      <Col xs={5} sm={5} md={5} lg={5} xl={5}>
        {indicator}
      </Col>
    </Row>
  </Menu.Item>
);

export default MenuWithoutPermission;
