/* eslint-disable import/prefer-default-export */
import { Yup, i18next } from '@lib/index';

const { t } = i18next;

export const changePasswordFormValidation = Yup.object({
  currentPassword: Yup.string().required(t('account.currentPasswordRequired')),
  password: Yup.string().required(t('account.newPasswordRequired')),
  passwordConfirmation: Yup.string().required(t('account.passwordConfirmationRequired')),
});
