/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { CamelToSnakeCaseNested } from '@helpers/index';
import {
  SiteWorkerApiProps, SiteWorkersApiProps, SiteWorkerParamsProps, SiteWorkerAddParamsProps,
} from './types';
import siteWorker from './siteworker';

const siteWorkerTag = baseApi.enhanceEndpoints({ addTagTypes: [siteWorker.tagType] });
const siteWorkerApi = siteWorkerTag.injectEndpoints({
  endpoints: (builder) => ({
    getSiteWorkers: builder.query<SiteWorkersApiProps,
    CamelToSnakeCaseNested<SiteWorkerParamsProps>>(
      siteWorker.getSiteWorkers(),
    ),
    getSiteWorker: builder.query<SiteWorkerApiProps, number>(siteWorker.getEntity()),
    addSiteWorker: builder.mutation<SiteWorkerApiProps, Partial<SiteWorkerApiProps>>(
      siteWorker.addEntity('sites'),
    ),
    updateSiteWorker: builder.mutation<void, Pick<SiteWorkerApiProps, 'id'> & Partial<SiteWorkerApiProps>>(siteWorker.updateEntity()),
    addAnnotation: builder.mutation<void, SiteWorkerAddParamsProps>(siteWorker.addAnnotation()),
  }),
  overrideExisting: false,
});

export const {
  useAddSiteWorkerMutation,
  useGetSiteWorkerQuery,
  useGetSiteWorkersQuery,
  useUpdateSiteWorkerMutation,
  useAddAnnotationMutation,
} = siteWorkerApi;
