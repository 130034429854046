import React, {
  useEffect, useCallback, ReactNode,
} from 'react';
import {
  Col, Row, Tag, Popover,
} from 'antd';
import { BarcodeOutlined, UserOutlined } from '@ant-design/icons';
import humps from 'lodash-humps-ts';
import { useTranslation } from 'react-i18next';
import JobStatusToast from '../../molecules/job-status-toast';
import { JobStatusProps, JobSituationDataProps } from './types';

const JobStatus = ({
  statusStr, archivedAt, stage, stageCount,
  expectedStaff, allowedStaff, activeStaff, user, jobSituations, openPopOver, loading,
  setOpenChangePopOver,
}: JobStatusProps & { jobSituations?: JobSituationDataProps[] }) => {
  const { t } = useTranslation();
  const [displayStatus, setDisplayStatus] = React.useState<(ReactNode)[]>([]);
  const displayDraftStatus = () => <Tag color="blue">{t('job.jobStatus.incomplete')}</Tag>;
  const displayArchivedStatus = () => <Tag color="red">{t('job.jobStatus.trashed')}</Tag>;
  const generateAcceptedJobStatus = useCallback(() => {
    let acceptedStatus = [
      <Tag key="accepted" color="green">
        A
        <small>
          x$
          {stage - 1}
        </small>
      </Tag>,
    ];

    if (allowedStaff !== null) {
      acceptedStatus = [...acceptedStatus, <Tag key="allowedStaff" color="blue" icon={<UserOutlined />}><small>{expectedStaff !== null ? `${allowedStaff}/${expectedStaff}` : allowedStaff}</small></Tag>];
    }

    if (activeStaff !== null) {
      acceptedStatus = [...acceptedStatus, <Tag key="activeStaff" color={activeStaff === 0 ? 'yellow' : 'grey'} icon={<BarcodeOutlined />}>{activeStaff}</Tag>];
    }
    setDisplayStatus(acceptedStatus);
  }, [activeStaff, allowedStaff, expectedStaff, stage]);

  const generatePreSteps = useCallback(() => {
    let otherStatus: ReactNode[] = [];
    let index = 0;
    while (index < stage) {
      if (index === 0) {
        otherStatus = [<Tag key={index} color="blue">C</Tag>];
      } else {
        otherStatus = [...otherStatus, <Tag key={index} color="green">A</Tag>];
      }
      index += 1;
    }
    setDisplayStatus(otherStatus);
  }, [stage]);

  const generatePostSteps = useCallback(() => {
    let otherStatus: (JSX.Element | Element)[] = [];

    let index = 1;
    while (index < stageCount) {
      otherStatus = [...otherStatus, <Tag key={index} color="grey">P</Tag>];
      index += 1;
    }
  }, [stageCount]);

  const generateRejectedJobStatus = useCallback(() => {
    let rejectedStatus: ReactNode[] = [];
    if (statusStr === 'rejected') {
      rejectedStatus = [...rejectedStatus, <Tag key="rejected" color="red">R</Tag>];
    } else if (user.blocking) {
      rejectedStatus = [...rejectedStatus, <Tag key="userBlocking" color="yellow">P</Tag>];
    } else {
      rejectedStatus = [...rejectedStatus, <Tag key="rejectedStatus" color="grey">P</Tag>];
    }
    setDisplayStatus(rejectedStatus);
  }, [statusStr, user.blocking]);

  const generateOtherJobStatus = useCallback(() => {
    generatePreSteps();
    generateRejectedJobStatus();
    generatePostSteps();
  }, [generatePreSteps, generateRejectedJobStatus, generatePostSteps]);

  useEffect(() => {
    if (stage === 0) {
      setDisplayStatus([displayDraftStatus()]);
      return;
    }
    if (archivedAt) {
      setDisplayStatus([displayArchivedStatus()]);
      return;
    }
    if (statusStr === 'accepted') {
      generateAcceptedJobStatus();
    } else {
      generateOtherJobStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, archivedAt, statusStr]);
  const jobSituationsData = humps(jobSituations) as JobSituationDataProps;

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Popover
          title="Status"
          content={(<JobStatusToast situations={jobSituationsData} loading={loading} />)}
          placement="bottom"
          trigger="click"
          visible={openPopOver}
          onVisibleChange={setOpenChangePopOver}
        >
          {displayStatus}
        </Popover>
      </Col>
    </Row>
  );
};

JobStatus.defaultProps = {
  jobSituations: [],
};

export default JobStatus;
