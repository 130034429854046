/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
export const ROLES = {
  admin: 'admin',
  administratorSection: 'administrator_section',
  areaSection: 'area_section',
  storeSection: 'store_section',
  staffingContractor: 'staffing_contractor',
  regularContractor: 'regular_contractor',
  corpSite: 'corp_site',
};

export const SCOPES = {
  canViewStaffingContractorDashboardPage: 'can-view-staffing-contractor-dashboard-page',
  canViewAreaSectionDashboardPage: 'can-view-area-section-dashboard-page',
  canCreateJobs: 'can-create-jobs',
  canRegisterVehicles: 'can-register-vehicles',
  canEditVehicles: 'can-edit-vehicles',
  canDeleteVehicles: 'can-delete-vehicles',
  canViewCorpSiteDashboardPage: 'can-view-corp-site-dashboard-page',
  canViewAccountPage: 'can-view-account-page',
  canViewSettingsPage: 'can-view-settings-page',
  canViewUsersPage: 'can-view-users-page',
  canViewJobsPage: 'can-view-jobs-page',
  canViewWorkersPage: 'can-view-workers-page',
  canViewDocumentationsPage: 'can-view-documentations-page',
  canViewVehiclesPage: 'can-view-vehicles-page',
  canViewAlertsPage: 'can-view-alerts-page',
  canViewStaffingDocumentAlertListPage: 'can-view-staffing-document-alerts-list-page',
  canViewAreaSectionDocumentAlertListPage: 'can-view-area-section-document-alerts-list-page',
  canViewStaffingDocumentationListPage: 'can-view-staffing-documentation-list-page',
  canViewHelpCentersPage: 'can-view-help-centers-page',
  canViewSiteWorkersPage: 'can-view-site-workers-site-page',
  canViewPersonnelPage: 'can-view-personnel-page',
  canViewAreaSectionJobListPage: 'can-view-area-section-job-list-page',
  canViewStaffingContractorJobListPage: 'can-view-staffing-contractor-job-list-page',
  canViewWorkerAccessesPage: 'can-view-worker-accesses-page',
  canViewAreaSectionAccessListPage: 'can-view-area-section-access-list-page',
  canViewStaffingContractorAccessListPage: 'can-view-staffing-contractor-access-list-page',
  canViewContractorsPage: 'can-view-contractors-page',
  canViewRegularContractorJobListPage: 'can-view-regular-contractor-job-list-page',
  canViewStoreSectionJobListPage: 'can-view-store-section-job-list-page',
  canViewAreaSectionContractorListPage: 'can-view-area-section-contractor-list-page',
  canViewCorpSiteContractorListPage: 'can-view-corp-site-contractor-list-page',
  canViewStoreSectionContractorListPage: 'can-view-store-section-contractor-list-page',
  canViewAreaSectionContractorFormPage: 'can-view-area-section-contractor-form-page',
  canViewCorpSiteContractorFormPage: 'can-view-corp-site-contractor-form-page',
  canViewRegularContractorAccessListPage: 'can-view-regular-contractor-access-list-page',
  canViewRegularDocumentationListPage: 'can-view-regular-documentation-list-page',
  canViewRegularContractorDashboardPage: 'can-view-regular-contractor-dashboard-page',
  canViewStoreSectionDashboardPage: 'can-view-store-section-dashboard-page',
  canViewCorpSiteContractorUserListPage: 'can-view-corp-site-contractor-user-list-page',
  canViewAreaSectionContractorUserListPage: 'can-view-area-section-contractor-user-list-page',
  canViewAreaSectionContractorDetailPage: 'can-view-area-section-contractor-detail-page',
  canViewCorpSiteContractorDetailPage: 'can-view-corp-site-contractor-detail-page',
  canViewStoreSectionContractorDetailPage: 'can-view-store-section-contractor-detail-page',
  canViewStaffingContractorF30Page: 'can-view-staffing-contractor-f30-page',
  canViewRegularContractorF30Page: 'can-view-regular-contractor-f30-page',
  canViewPaymentOutcomeButton: 'can-view-payment-outcome-button',
  canViewF30Card: 'can-view-f30-card',
  canViewInvoicesPage: 'can-view-invoices-page',
  canViewStaffingContractorJobDetailPage: 'can-view-staffing-contractor-job-detail-page',
  canViewAreaSectionJobDetailPage: 'can-view-area-section-job-detail',
  canViewRegularContractorJobDetailPage: 'can-view-regular-contractor-job-detail',
  canViewStoreSectionJobDetailPage: 'can-view-store-section-job-detail',
  canViewAreaSectionJobFormPage: 'can-view-area-section-job-form-page',
  canViewRegularContractorJobFormPage: 'can-view-regular-contractor-job-form-page',
  canViewAdministratorSectionJobDetailPage: 'can-view-administrator-section-job-detail-page',
  canViewAdministratorSectionJobListPage: 'can-view-administrator-section-job-list-page',
  canViewStoreSectionJobFormPage: 'can-view-store-section-job-form-page',
  canViewStaffingContractorJobFormPage: 'can-view-staffing-contractor-job-form-page',
  canViewStoreSectionContractorFormPage: 'can-view-store-section-contractor-form-page',
  canViewAreaSectionVehiclesPage: 'can-view-area-section-vehicles-page',
};

export const PERMISSIONS = {
  [ROLES.staffingContractor]: [
    SCOPES.canViewStaffingContractorDashboardPage,
    SCOPES.canViewAccountPage,
    SCOPES.canViewJobsPage,
    SCOPES.canViewWorkersPage,
    SCOPES.canViewDocumentationsPage,
    SCOPES.canViewVehiclesPage,
    SCOPES.canViewStaffingDocumentAlertListPage,
    SCOPES.canViewHelpCentersPage,
    SCOPES.canViewAlertsPage,
    SCOPES.canViewWorkerAccessesPage,
    SCOPES.canViewStaffingContractorAccessListPage,
    SCOPES.canViewStaffingContractorJobListPage,
    SCOPES.canViewStaffingDocumentationListPage,
    SCOPES.canViewStaffingContractorJobDetailPage,
    SCOPES.canViewStaffingContractorJobFormPage,
  ],
  [ROLES.areaSection]: [
    SCOPES.canViewAreaSectionDashboardPage,
    SCOPES.canCreateJobs,
    SCOPES.canViewAccountPage,
    SCOPES.canViewSiteWorkersPage,
    SCOPES.canViewPersonnelPage,
    SCOPES.canViewAreaSectionJobListPage,
    SCOPES.canViewJobsPage,
    SCOPES.canViewAlertsPage,
    SCOPES.canViewAreaSectionDocumentAlertListPage,
    SCOPES.canViewWorkerAccessesPage,
    SCOPES.canViewAreaSectionAccessListPage,
    SCOPES.canViewContractorsPage,
    SCOPES.canViewAreaSectionJobListPage,
    SCOPES.canViewAreaSectionContractorListPage,
    SCOPES.canViewAreaSectionContractorFormPage,
    SCOPES.canViewHelpCentersPage,
    SCOPES.canViewAreaSectionContractorUserListPage,
    SCOPES.canViewAreaSectionContractorDetailPage,
    SCOPES.canViewAreaSectionJobDetailPage,
    SCOPES.canViewAreaSectionJobFormPage,
    SCOPES.canRegisterVehicles,
    SCOPES.canEditVehicles,
    SCOPES.canDeleteVehicles,
  ],
  [ROLES.corpSite]: [
    SCOPES.canViewCorpSiteDashboardPage,
    SCOPES.canCreateJobs,
    SCOPES.canViewSettingsPage,
    SCOPES.canViewUsersPage,
    SCOPES.canViewContractorsPage,
    SCOPES.canViewCorpSiteContractorListPage,
    SCOPES.canViewCorpSiteContractorFormPage,
    SCOPES.canViewCorpSiteContractorUserListPage,
    SCOPES.canViewCorpSiteContractorDetailPage,
  ],
  [ROLES.administratorSection]: [
    SCOPES.canViewAreaSectionDashboardPage,
    SCOPES.canCreateJobs,
    SCOPES.canViewAccountPage,
    SCOPES.canViewSiteWorkersPage,
    SCOPES.canViewUsersPage,
    SCOPES.canViewAlertsPage,
    SCOPES.canViewWorkerAccessesPage,
    SCOPES.canViewAreaSectionAccessListPage,
    SCOPES.canViewContractorsPage,
    SCOPES.canViewJobsPage,
    SCOPES.canViewAdministratorSectionJobDetailPage,
    SCOPES.canViewAdministratorSectionJobListPage,
  ],
  [ROLES.regularContractor]: [
    SCOPES.canViewAccountPage,
    SCOPES.canViewHelpCentersPage,
    SCOPES.canViewWorkerAccessesPage,
    SCOPES.canViewRegularContractorAccessListPage,
    SCOPES.canViewRegularDocumentationListPage,
    SCOPES.canViewRegularContractorDashboardPage,
    SCOPES.canViewRegularContractorJobListPage,
    SCOPES.canViewJobsPage,
    SCOPES.canViewRegularContractorJobFormPage,
    SCOPES.canViewRegularContractorJobDetailPage,
  ],
  [ROLES.storeSection]: [
    SCOPES.canViewJobsPage,
    SCOPES.canViewAccountPage,
    SCOPES.canViewStoreSectionDashboardPage,
    SCOPES.canViewHelpCentersPage,
    SCOPES.canViewContractorsPage,
    SCOPES.canViewStoreSectionContractorListPage,
    SCOPES.canViewContractorsPage,
    SCOPES.canViewStoreSectionContractorDetailPage,
    SCOPES.canViewStoreSectionJobDetailPage,
    SCOPES.canViewJobsPage,
    SCOPES.canViewStoreSectionJobListPage,
    SCOPES.canViewStoreSectionJobFormPage,
    SCOPES.canViewStoreSectionJobDetailPage,
    SCOPES.canViewStoreSectionContractorFormPage,
  ],
};
