import React from 'react';
import ReactDOM from 'react-dom';
import '@styles/css/antd.css';
import '@styles/css/global.css';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from '@app/app';
import { HelmetProvider } from 'react-helmet-async';
import { store } from '@app/store';
import { Provider } from 'react-redux';

const dsn = import.meta.env.VITE_DSN;

Sentry.init({
  dsn,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
