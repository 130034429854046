import React, { useEffect } from 'react';
import {
  useNavigate, Card, Typography, eventBus, textColor, useAppDispatch,
} from '@lib/index';
import { logOut } from '@features/account/AccountSlice';

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [count, setCount] = React.useState(3);
  const { Title } = Typography;
  useEffect(() => {
    eventBus.dispatch('logout', {});
    dispatch(logOut());
    const interval = setInterval(() => {
      setCount((preState) => preState - 1);
      if (count === 0) { navigate('/login'); }
    }, 1000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, navigate]);

  return (
    <Card className="flex justify-center w-full my-64 lg:my-96">
      <Title className={`font-semibold ${textColor.gray}`} level={2}>
        {' '}
        Session Expired, Redirecting to login page in
        {' '}
        {count}
        {' '}
        seconds
      </Title>
    </Card>
  );
};

export default Logout;
