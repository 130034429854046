import { providesList } from '@helpers/index';
import BaseService from '../base';
import { SiteWorkerAddParamsProps, SiteWorkerParamsProps, SiteWorkersApiProps } from './types';

class SiteWorker extends BaseService {
  addAnnotation() {
    return {
      query: ({ ...body }: SiteWorkerAddParamsProps) => ({
        url: '/notes',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: this.tagType },
      ],
    };
  }

  getSiteWorkers() {
    return {
      query: ({ id, ...params }: SiteWorkerParamsProps) => ({
        url: `sites/${id}/site-workers`,
        params,
      }),

      providesTags: (result: SiteWorkersApiProps | undefined) => (
        providesList(result?.workers, this.tagType)
      ),
    };
  }
}

export default new SiteWorker('site-workers', 'SiteWorker');
