/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import {
  ContractorWorkerApiProps,
  ContractorWorkerParamsProps,
  SendCSVApiProps,
  WorkerApiProps,
  WorkersApiProps,
  WorkerSectionFilterParams,
} from './types';
import worker from './worker';

const workerTag = baseApi.enhanceEndpoints({ addTagTypes: [worker.tagType] });
const workerAPi = workerTag.injectEndpoints({
  endpoints: (builder) => ({
    getWorkers: builder.query<WorkersApiProps, number>(
      worker.getEntitiesWithId('contractors'),
    ),
    getContractorWorkers: builder.query<ContractorWorkerApiProps, ContractorWorkerParamsProps>(
      worker.getContractorWorkers(),
    ),
    getWorker: builder.query<WorkerApiProps, number>(worker.getEntity()),
    addWorker: builder.mutation<WorkerApiProps, Partial<WorkerApiProps>>(
      worker.addEntity('contractors'),
    ),
    updateWorker: builder.mutation<void, Pick<WorkerApiProps, 'id'> & Partial<WorkerApiProps>>(worker.updateEntity()),
    deleteWorker: builder.mutation<{ success: boolean; id: number }, number>(
      worker.deleteEntity(),
    ),
    deleteAllWorkers: builder.mutation<{ success: boolean; id: number }, number>(
      worker.deleteEntityAll('contractors'),
    ),
    sendWorkersCSV: builder.mutation<SendCSVApiProps, Partial<SendCSVApiProps>>(
      worker.sendReport('user-reports'),
    ),
    getSectionWorkers: builder.query<ContractorWorkerApiProps, WorkerSectionFilterParams>(
      worker.getSectionWorkers(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useAddWorkerMutation,
  useDeleteWorkerMutation,
  useGetWorkerQuery,
  useGetWorkersQuery,
  useGetContractorWorkersQuery,
  useUpdateWorkerMutation,
  useSendWorkersCSVMutation,
  useDeleteAllWorkersMutation,
  useGetSectionWorkersQuery,
} = workerAPi;
