import BaseService from '../base';
import {
  InvoiceStatsParams,
  InvoiceWorkersApiProps,
  InvoiceWorkersParams,
  UploadInvoiceParams,
} from './types';

class Invoice extends BaseService {
  getInvoiceStatsByMonth() {
    return {
      query: ({ contractorId, period }: InvoiceStatsParams) => ({
        url: `contractors/${contractorId}/invoices/${period}`,
      }),
      providesTags: [{ type: this.tagType }],
    };
  }

  getInvoiceWorkers() {
    return {
      query: ({ contractorId, period, ...params }: InvoiceWorkersParams) => ({
        url: `contractors/${contractorId}/invoices/${period}/workers`,
        params,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: InvoiceWorkersApiProps) => [{ type: this.tagType }],
    };
  }

  uploadInvoice() {
    return {
      query: ({ contractorId, period, ...body }: UploadInvoiceParams) => ({
        url: `contractors/${contractorId}/invoices/${period}/documents`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: this.tagType }],
    };
  }
}

export default new Invoice('invoices', 'Invoice');
