/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import { providesList } from '@helpers/index';
import BaseService from '../base';
import {
  ContractorWorkerParamsProps, ContractorWorkerApiProps, WorkerSectionFilterParams,
} from './types';

class Worker extends BaseService {
  getContractorWorkers() {
    return {
      query: ({ contractorId, ...params }: ContractorWorkerParamsProps) => ({
        url: `/contractors/${contractorId}/workers-list`,
        params,
      }),
      providesTags: (result?: ContractorWorkerApiProps) => providesList(result?.workers, this.tagType),
    };
  }

  getSectionWorkers() {
    return {
      query: ({ sectionId, ...params }: WorkerSectionFilterParams) => ({
        url: `/sections/${sectionId}/workers-list`,
        params,
      }),
      providesTags: (result?: ContractorWorkerApiProps) => providesList(result?.workers, this.tagType),
    };
  }
}

export default new Worker('workers', 'Worker');
