/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import afp from './afp';
import { AfpsApiProps } from './types';

// Add Tag to the list of tags to be invalidated when the list of afps is updated
const afpTag = baseApi.enhanceEndpoints({ addTagTypes: [afp.tagType] });
const afpApi = afpTag.injectEndpoints({
  endpoints: (builder) => ({
    getAfps: builder.query<AfpsApiProps, void>(afp.getEntities()),
  }),
  overrideExisting: false,
});

export const { useGetAfpsQuery } = afpApi;
