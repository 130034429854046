/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react';
import {
  Col, Row, Button, Divider, Typography,
  UserOutlined, roleService, useSwitchProfile, Popover, humps,
  useTranslation, camelCase, useNavigate, orderBy,
} from '@lib/index';
import { RoleProps } from '../types';

export const SwitchProfile = () => {
  const { Text, Paragraph } = Typography;
  const { useGetRolesQuery } = roleService;
  const navigate = useNavigate();
  const { data } = useGetRolesQuery();
  const roles = humps(data) as RoleProps[];
  const { roleId } = useSwitchProfile();
  const handleSwitchRole = async (id: number) => navigate(`switching/${id}`);
  const allowedRoles = import.meta.env.VITE_ALLOWED_ROLES as string;
  const { t } = useTranslation();
  const permittedRoles = roles?.filter((role) => allowedRoles.includes(role.category));
  const orderedRoles = orderBy(permittedRoles, [(role) => role.roleLabel.split('')[0]], 'asc');
  const userRoles = (
    <Row className="w-64 overflow-y-auto h-72">
      {
        orderedRoles?.map((role) => (
          <Fragment key={role.id}>
            <Col span={12}>
              <Paragraph>
                <Text className="font-bold text-xs">{role.roleLabel}</Text>
                {' '}
                {t(`roles.${camelCase(role.category)}`)}
              </Paragraph>
            </Col>
            <Col span={12}>
              <Button
                shape="round"
                type={`${role.id === roleId ? 'primary' : 'default'}`}
                onClick={() => handleSwitchRole(role.id)}
                disabled={role.id === roleId}
              >
                {role.id === roleId ? t('login.active') : t('login.switch')}
              </Button>
            </Col>
            <Divider className="my-2" />
          </Fragment>
        ))
      }
    </Row>
  );
  return (
    <Popover
      placement="bottom"
      content={userRoles}
      trigger="click"
    >
      <UserOutlined />
    </Popover>
  );
};
