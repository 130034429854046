import BaseService from '../base';
import { WorkerSection } from './types';

class SectionWorker extends BaseService {
  delete() {
    return {
      query: (id: number) => ({
        url: `/section-workers/${id}`,
        method: 'delete',
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Worker' }, { type: 'Job' }],
    };
  }

  updateSectionWorker() {
    return {
      query: ({ id, ...params }: WorkerSection) => ({
        url: `/section-workers/${id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Worker' }],
    };
  }
}

export default new SectionWorker('section-workers', 'SectionWorker');
