/* eslint-disable max-len */
import { providesList } from '@helpers/index';
import BaseService from '../base';
import { MetaContractorSubcategoriesApiProps } from './types';

class MetaContractorSubcategory extends BaseService {
  getMetaContractorSubcategories(secondaryUrl: string) {
    return {
      query: (id: number) => ({
        url: this.checkUrl(id, secondaryUrl),
      }),
      providesTags: (result?: MetaContractorSubcategoriesApiProps) => providesList(result?.metaContractorSubcategories, this.tagType),
    };
  }
}

export default new MetaContractorSubcategory('meta-contractor-subcategories', 'MetaContractorSubcategory');
