/* eslint-disable import/no-cycle */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { queryParamsToSnakeCase } from '@helpers/index';
import { baseUrl } from '../../_config';
import { RootState } from '../../_app/types';

const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { currentUser } = (getState() as RootState).account;
      const token = currentUser?.token;
      const roleToken = currentUser?.kitchen;
      if (token && ['getUserRoles', 'loginRole', 'updateUserPassword'].includes(endpoint)) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      if (roleToken && !['getUserRoles', 'loginRole'].includes(endpoint)) {
        headers.set('Authorization', `Bearer ${roleToken}`);
      }
      return headers;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paramsSerializer: (params: Record<string, any>) => (queryParamsToSnakeCase(params)),
  }),
  endpoints: () => ({}),
});
export default baseApi;
