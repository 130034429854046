import { LoginResponseProps } from '@features/account/types';
import { toSnakeCase } from '@helpers/toConvertCase';
import { baseUrl } from '../../_config';

export const logOut = (): void => localStorage.removeItem('token');

export const logIn = async (email: string, password: string, recaptcha: string) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: { uname: email, password }, captcha: recaptcha }),
  };

  const response = await fetch(`${baseUrl}/auth/login`, requestOptions);
  return response;
};

export const setLocalStorage = async (
  data: Pick<LoginResponseProps, 'user_id' | 'email' | 'first_name'>,
) => {
  localStorage.setItem('currentUser', JSON.stringify(data));
};

export const loginRole = async (id: number, token: string) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}}`,
    },
    body: JSON.stringify({ role_id: id }),
  };

  const response = await fetch(`${baseUrl}/kitchen/sign_in`, requestOptions);
  return response;
};

export const getRolePermissions = async (token: string) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(`${baseUrl}/permissions`, requestOptions);
  return response;
};

export const changePasswordWithToken = async ({
  password,
  passwordConfirmation,
  resetPasswordToken,
}: {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
}) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      toSnakeCase({ password, passwordConfirmation, resetPasswordToken }),
    ),
  };

  const response = await fetch(`${baseUrl}/auth/recover-password`, requestOptions);
  return response;
};

export const forgotPassword = async ({
  email,
  reCaptcha,
}: {
  email: string;
  reCaptcha: string;
}) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, captcha: reCaptcha }),
  };

  const response = await fetch(`${baseUrl}/auth/forgot-password`, requestOptions);
  return response;
};
