/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useGetPermissions } from '@hooks/index';
import { Col, Menu, Row } from 'antd';
import { MenuPermissionGateProps } from './types';

const hasPermission = ({ permissions, scopes }: { permissions: string[]; scopes: string[] }) => {
  const scopesMap: { [index: string]: boolean } = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions?.some((permission) => scopesMap[permission]);
};

const MenuPermissionsGate = ({
  icon,
  scopes = [],
  handleLink,
  title,
  indicator,
  ...props
}: MenuPermissionGateProps) => {
  const permissions = useGetPermissions();
  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted) return <></>;

  return (
    <Menu.Item icon={icon} onClick={handleLink} {...props}>
      <Row>
        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
          {title}
        </Col>
        {indicator && (
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          {indicator}
        </Col>
        )}
      </Row>
    </Menu.Item>
  );
};

export default MenuPermissionsGate;
