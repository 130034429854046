// eslint-disable-next-line react/jsx-props-no-spreading
import React from 'react';
import { FormItem, DatePicker } from 'formik-antd';
import { Moment } from 'moment';
import { FormInputValues, RangeProps } from './types';

const { RangePicker } = DatePicker;

const InputRangeField = ({
  name,
  size,
  label,
  className,
  disabledDate,
  format,
  onCalendarChange,
}: FormInputValues & {
  disabledDate: (date: Moment) => boolean;
  onCalendarChange: (val: RangeProps) => void;
}) => (
  <FormItem name={name} label={label}>
    <RangePicker
      name={name}
      size={size}
      className={className}
      disabledDate={disabledDate}
      format={format}
      onCalendarChange={onCalendarChange}
    />
  </FormItem>
);

export default InputRangeField;
