/* eslint-disable class-methods-use-this */
import BaseService from '../base';
import { CorpSiteStatsParams } from './types';

class Stat extends BaseService {
  getStaffingContractorDashboard() {
    return {
      query: (contractorId: number) => ({
        url: `/contractors/${contractorId}/staffing-dashboard`,
      }),
      providesTags: [{ type: this.tagType }],
    };
  }

  getRegularContractorDashboard() {
    return {
      query: (contractorId: number) => ({
        url: `/contractors/${contractorId}/regular-dashboard`,
      }),
      providesTags: [{ type: this.tagType }],
    };
  }

  getAreaSectionDashboard() {
    return {
      query: (areaSectionId: number) => ({
        url: `/sections/${areaSectionId}/area-dashboard`,
      }),
      providesTags: [{ type: this.tagType }],
    };
  }

  getStoreSectionDashboard() {
    return {
      query: (storeSectionId: number) => ({
        url: `/sections/${storeSectionId}/store-dashboard`,
      }),
      providesTags: [{ type: this.tagType }],
    };
  }

  getCorpSiteDashboard() {
    return {
      query: ({ id, ...params }: CorpSiteStatsParams) => ({
        url: `/sites/${id}/corp-site-dashboard`,
        params,
      }),
      providesTags: [{ type: this.tagType }],
    };
  }
}

export default new Stat('dashboard', 'Stat');
