/* eslint-disable camelcase */
import { providesList } from '@helpers/index';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import BaseService from '../base';
import {
  JobsApiProps,
  JobParamsProps,
  JobSituationsApiProps,
  ContractStatusApiProps,
  DocStatusApiProps,
  JobReviewParamsProps,
  JobShiftParamsProps,
  JobPlacementParamsProps,
  JobGroupParamsProps,
  JobContractApiProps,
  JobContract,
  JobOutComeProps,
  ContractorVehicleApiParams,
} from './types';

class Job extends BaseService {
  getJobs() {
    return {
      query: (params: JobParamsProps) => ({
        url: 'users/current/jobs',
        params,
      }),
      providesTags: (result?: JobsApiProps) => providesList(result?.jobs, this.tagType),
    };
  }

  getDocStatus() {
    return {
      query: (id: number) => ({
        url: `${this.url}/${id}/documentation-status`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: DocStatusApiProps) => [{ type: this.tagType }],
    };
  }

  getContractStatus() {
    return {
      query: (id: number) => ({
        url: `${this.url}/${id}/contracts-status`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: ContractStatusApiProps) => [{ type: this.tagType }],
    };
  }

  getJobSituation() {
    return {
      query: (id: number) => ({
        url: `${this.url}/${id}/situation`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: JobSituationsApiProps) => [{ type: this.tagType }],
    };
  }

  addReview() {
    return {
      query: ({ id, ...body }: JobReviewParamsProps) => ({
        url: `${this.url}/${id}/states`,
        method: 'POST',
        body,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      invalidatesTags: [{ type: this.tagType }],
    };
  }

  addJobPlacement() {
    return {
      query: ({ id, ...body }: JobPlacementParamsProps) => ({
        url: `${this.url}/${id}/placements`,
        method: 'POST',
        body,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      invalidatesTags: (
        result: void | undefined,
        error: FetchBaseQueryError | undefined,
        { id }: { id: number },
      ) => [{ type: this.tagType, id }],
    };
  }

  addJobShift() {
    return {
      query: ({ id, ...body }: JobShiftParamsProps) => ({
        url: `${this.url}/${id}/shifts`,
        method: 'POST',
        body,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      invalidatesTags: (
        result: void | undefined,
        error: FetchBaseQueryError | undefined,
        { id }: { id: number },
      ) => [{ type: this.tagType, id }],
    };
  }

  updateJobShift(secondaryUrl?: string) {
    return {
      query: ({ id, ...body }: JobShiftParamsProps) => ({
        url: `${secondaryUrl}/${id}`,
        method: 'PATCH',
        body,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      invalidatesTags: (
        result: void | undefined,
        error: FetchBaseQueryError | undefined,
        { id }: { id: number },
      ) => [{ type: this.tagType, id }],
    };
  }

  addJobGroup() {
    return {
      query: ({ id, ...body }: JobGroupParamsProps) => ({
        url: `${this.url}/${id}/groups`,
        method: 'POST',
        body,
      }),
    };
  }

  deleteJobEntity(secondaryUrl?: string) {
    return {
      query: ({ id }: { id: number; jobId: number }) => ({
        url: `${secondaryUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (
        result: { success: boolean; id: number } | undefined,
        error: FetchBaseQueryError | undefined,
        { jobId }: { jobId: number },
      ) => [{ type: this.tagType, id: jobId }],
    };
  }

  getJobContracts() {
    return {
      query: (id: number) => ({
        url: `${this.url}/${id}/contracts?visible=true`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: JobContractApiProps) => [{ type: this.tagType }],
    };
  }

  createJobContract() {
    return {
      query: ({ id, ...params }: JobContract) => ({
        url: `${this.url}/${id}/contracts`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Contract' }, { type: 'Worker' }, { type: 'Job' }],
    };
  }

  updateJobOutCome() {
    return {
      query: ({ jobId, ...patch }: JobOutComeProps) => ({
        url: `${this.url}/${jobId}/outcomes`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: [{ type: this.tagType }],
    };
  }

  updateJobEvaluation() {
    return {
      query: ({ id, jobId, ...body }: { id: number, jobId: number }) => ({
        url: `${this.url}/${jobId}/evaluations/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: this.tagType }],
    };
  }

  createJobVehicle() {
    return {
      query: ({ id, ...params }: ContractorVehicleApiParams) => ({
        url: `${this.url}/${id}/vehicles`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Vehicle' }, { type: 'Job' }],
    };
  }

  deleteJobVehicle() {
    return {
      query: (id: number) => ({
        url: `/vehicles/${id}`,
        method: 'delete',
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Vehicle' }, { type: 'Job' }],
    };
  }
}

export default new Job('jobs', 'Job');
