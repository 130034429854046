/* eslint-disable import/no-absolute-path */
import React, { useState } from 'react';
import {
  UserOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  UnlockOutlined,
  ProfileOutlined,
  BellOutlined,
  TeamOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  LogoutOutlined,
  Layout,
  Menu,
  useNavigate,
  useAppDispatch,
  eventBus,
  MenuPermissionsGate,
  useTranslation,
  documentAlertService,
  humps,
  SideBarNotification,
  SolutionOutlined,
  useAppSelector,
  jobService,
  MenuWithoutPermission,
  usePermissions,
} from '@lib/index';
import Logo from '/images/logo.png';
import LogoTwo from '/images/logo2.png';
import { SCOPES } from '@config/permissionsMap';
import { logOut } from '@features/account/AccountSlice';
import { SideBarProps } from './types';

const SideBar = ({ triggered, collapsed, className = '' }: SideBarProps) => {
  const { Sider } = Layout;
  const { t } = useTranslation();
  const { currentUser } = useAppSelector((state) => state.account);
  const { role } = currentUser;
  const { useGetPendingAlertsQuery } = documentAlertService;
  const skip = !['staffing_contractor', 'area_section'].includes(role);
  const { data } = useGetPendingAlertsQuery(undefined, { skip });
  const pendingAlerts = humps(data?.count);
  const [sideBarVisible, setSideBarVisible] = useState<boolean>(
    window.innerWidth >= 576,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sideBarHiddenClassName = !collapsed
    ? 'fixed top-0 left-0 h-full z-10 overflow-auto'
    : 'hidden';
  const sideBarClassName = sideBarVisible ? className : sideBarHiddenClassName;
  const LogoImg = !collapsed ? Logo : LogoTwo;
  const handleDashboard = () => navigate('/dashboard');
  const handleWorkers = () => navigate('/workers');
  const handleHelpCenter = () => navigate('/help-center');
  const handleWorkersAccesses = () => navigate('/workers-accesses?page=0&tab=accesses');
  const handleAccounts = () => navigate('/accounts');
  const handlePersonnel = () => navigate('/personnel');
  const handleLogout = () => {
    eventBus.dispatch('logout', {});
    dispatch(logOut());
    navigate('/login');
  };
  const handleJobList = () => navigate('/jobs?page=0');
  const handleDocumentations = () => navigate('/documentations?tab=general');
  const handleAlerts = () => navigate('/alerts');
  const handleSettings = () => navigate('/settings');
  const handleUsers = () => navigate('/users');
  const handleSiteWorkers = () => navigate('/site-workers');
  const handleContractor = () => navigate('/contractors');
  const handleF30 = () => navigate('/f30');
  const { canViewRegularContractorF30Page } = usePermissions();

  const { useGetJobsQuery } = jobService;
  const { data: jobsData } = useGetJobsQuery({
    page: 0,
    status: 'user_pending',
    scheduled: 'not_finished',
  });

  return (
    <Sider
      width="250px"
      className={sideBarClassName}
      trigger={null}
      collapsible
      collapsed={triggered}
      breakpoint="sm"
      onBreakpoint={(broken: boolean) => {
        if (broken) return setSideBarVisible(false);
        return setSideBarVisible(true);
      }}
    >
      <img className="logo h-8 pl-8 my-8" src={LogoImg} alt="Logo" />
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} inlineIndent={35}>
        <Menu.Item key="1" icon={<AppstoreOutlined />} onClick={handleDashboard}>
          {t('sidebar.dashboard')}
        </Menu.Item>
        <MenuPermissionsGate
          scopes={[SCOPES.canViewJobsPage]}
          key="2"
          icon={<FileTextOutlined />}
          handleLink={handleJobList}
          title={t('sidebar.request')}
          indicator={(<SideBarNotification count={jobsData?.meta?.total_count as number} />)}
        />

        <MenuPermissionsGate
          scopes={[SCOPES.canViewContractorsPage]}
          key="3"
          icon={<SolutionOutlined />}
          handleLink={handleContractor}
          title={t('sidebar.contractors')}
        />

        <MenuPermissionsGate
          scopes={[SCOPES.canViewDocumentationsPage]}
          key="4"
          icon={<ProfileOutlined />}
          handleLink={handleDocumentations}
          title={t('sidebar.documentation')}
        />
        {canViewRegularContractorF30Page && (
          <MenuWithoutPermission
            key="7"
            icon={<ProfileOutlined />}
            handleLink={handleF30}
            title={t('sidebar.documentation')}
          />
        )}

        <MenuPermissionsGate
          scopes={[SCOPES.canViewWorkersPage]}
          key="6"
          icon={<UserOutlined />}
          handleLink={handleWorkers}
          title={t('sidebar.workers')}
        />

        <MenuPermissionsGate
          scopes={[SCOPES.canViewSiteWorkersPage]}
          key="7"
          icon={<UserOutlined />}
          handleLink={handleSiteWorkers}
          title={t('sidebar.siteWorkers')}
        />

        <MenuPermissionsGate
          scopes={[SCOPES.canViewPersonnelPage]}
          key="8"
          icon={<TeamOutlined />}
          handleLink={handlePersonnel}
          title={t('sidebar.personnel')}
        />

        {/* <MenuPermissionsGate
          scopes={[SCOPES.canViewVehiclesPage]}
          key="9"
          icon={<CarOutlined />}
          handleLink={() => null}
          title={t('sidebar.vehicles')}
        /> */}
        <MenuPermissionsGate
          scopes={[SCOPES.canViewAlertsPage]}
          key="10"
          icon={<BellOutlined />}
          handleLink={handleAlerts}
          title={t('sidebar.alerts')}
          indicator={(<SideBarNotification count={pendingAlerts} />)}
          data-cy="alert"
        />

        <MenuPermissionsGate
          scopes={[SCOPES.canViewWorkerAccessesPage]}
          key="11"
          icon={<UnlockOutlined />}
          handleLink={handleWorkersAccesses}
          title={t('sidebar.workerAccesses')}
        />

        <MenuPermissionsGate
          scopes={[SCOPES.canViewUsersPage]}
          key="12"
          icon={<UserOutlined />}
          handleLink={handleUsers}
          title={t('sidebar.users')}
        />
        <MenuPermissionsGate
          scopes={[SCOPES.canViewSettingsPage]}
          key="13"
          icon={<SettingOutlined />}
          handleLink={handleSettings}
          title={t('sidebar.settings')}
        />

        <MenuPermissionsGate
          scopes={[SCOPES.canViewHelpCentersPage]}
          key="14"
          icon={<QuestionCircleOutlined />}
          handleLink={handleHelpCenter}
          title={t('sidebar.helpCenter')}
        />

        <MenuPermissionsGate
          scopes={[SCOPES.canViewAccountPage]}
          key="15"
          icon={<SettingOutlined />}
          handleLink={handleAccounts}
          title={t('sidebar.accounts')}
        />

        <Menu.Item
          className="mt-72"
          key="16"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >
          {t('sidebar.logout')}
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideBar;
