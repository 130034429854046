import BaseService from '../base';
import { SectionContractorsApiProps, JobApiProps, JobParamsProps } from './types';

class Section extends BaseService {
  getContractors() {
    return {
      query: (id: number) => ({
        url: `${this.url}/${id}/contractors-list`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: SectionContractorsApiProps) => [{ type: this.tagType }],
    };
  }

  addJob() {
    return {
      query: ({ id, ...params }: JobParamsProps) => ({
        url: `${this.url}/${id}/jobs`,
        method: 'POST',
        body: params,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      invalidatesTags: (result?: JobApiProps) => [{ type: this.tagType }],
    };
  }
}

export default new Section('sections', 'Section');
