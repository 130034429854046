// eslint-disable-next-line react/jsx-props-no-spreading
import React from 'react';
import { Button } from 'antd';
import { BasicButtonProps } from './types';

const BasicButtonBG = ({
  text,
  onClick,
  icon,
  loading,
  buttonColorType,
  ...props
}: BasicButtonProps) => {
  const buttonColor = {
    red: 'bg-red-500 border-none',
    green: 'bg-green-500 border-none',
    default: 'text-blue-900 bg-transparent border-indigo-900',
    blue: 'bg-cyan-900 border-none',
    lightBlue: 'bg-blue-100 border-blue-200 border-solid',
    lightRed: 'bg-red-50 border-red-200 border-solid',
    yellow: 'bg-yellow-500 border-none text-black',
  };
  const buttonColorStyle = buttonColorType && buttonColor[buttonColorType];

  return (
    <Button
      className={`flex flex-row items-end rounded shadow-xl text-sm xs:text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm 2xl:text-sm ${buttonColorStyle}`}
      onClick={onClick}
      loading={loading}
      {...props}
    >
      {icon}
      {text}
    </Button>
  );
};
export default BasicButtonBG;
