/* eslint-disable import/prefer-default-export */

type ResponseProps = {
  text: () => Promise<string>;
  ok: boolean;
  status: number;
  statusText: string;
};

export const handleResponse = (response: ResponseProps) => response.text().then((text) => {
  const data = text && JSON.parse(text);
  if (!response.ok) {
    if ([401, 403].indexOf(response.status) !== -1) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      localStorage.removeItem('persist:root');
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }

  return data;
});
