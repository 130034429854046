import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatDayWithUtc = (milliseconds: number, format: string) => {
  const date = new Date(milliseconds).toUTCString();
  return dayjs.utc(date).format(format);
};

export const formatDay = (milliseconds: number, format: string) => {
  const date = new Date(milliseconds);
  return dayjs(date).format(format);
};

export const isWeekend = (milliseconds: number) => {
  const date = new Date(milliseconds).toUTCString();
  const day = dayjs.utc(date).format('d');
  return +day === 0 || +day === 6;
};

// Convert date from & until to an array of milliseconds
export const dateArray = (from?: string, to?: string) => {
  const datePoints: number[] = [];
  if (from && to) {
    const dateFrom = Date.parse(from);
    let dateTo = Date.parse(to);
    while (dateTo >= dateFrom) {
      datePoints.push(dateTo);
      dateTo -= 86400000;
    }
  }
  return datePoints;
};

export const calcPercent = (point: { expected: number; real: number }) => {
  const result = point.real / point.expected;
  return point.expected === 0 ? 'x' : (result * 100).toFixed(1);
};

/* eslint-disable max-len */
export const displayRow = (placementId: number, clickedRows: number[]) => _.includes(clickedRows, placementId);
