import React from 'react';
import {
  Col, Divider, Modal, Row, textColor, Typography, Radio,
  RadioChangeEvent, useSwitchProfile, orderBy,
  useTranslation, DetailText, camelCase, BasicButtonBG,
} from '@lib/index';

const SelectProfile = () => {
  const { Paragraph, Title } = Typography;
  const {
    roles, handleCancel, handleSelectProfile, setId, isModalVisible, id,
  } = useSwitchProfile();
  const onChange = (e: RadioChangeEvent) => setId(e.target.value);
  const { t } = useTranslation();
  const allowedRoles = import.meta.env.VITE_ALLOWED_ROLES as string;
  const permittedRoles = roles?.filter((role) => allowedRoles.includes(role.category));
  const orderedRoles = orderBy(permittedRoles, [(role) => role.roleLabel.split('')[0]], 'asc');

  return (
    <Modal
      title={(
        <Title level={5} className={`${textColor.darkBlue}`}>
          {t('login.selectProfile')}
        </Title>
      )}
      visible={isModalVisible}
      displayButtons
      width={700}
      footer={(
        <Row justify="end">
          <BasicButtonBG
            text={t('login.cancel')}
            loading={false}
            onClick={handleCancel}
            data-cy="select-profile-cancel"
          />
          <BasicButtonBG
            text={t('login.submit')}
            loading={false}
            onClick={handleSelectProfile}
            type="primary"
            data-cy="select-profile-submit"
          />
        </Row>
      )}
    >
      <Row className="overflow-y-auto h-72 rounded pl-3">
        <Radio.Group onChange={onChange} value={id} size="large">
          {orderedRoles?.map((role) => (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} key={role.id}>
              <Radio value={role.id} data-cy={`select-profile-role-${role.id}`}>
                <Paragraph>
                  (
                  {t(`roles.${camelCase(role.category)}`)}
                  )
                  {' '}
                  <DetailText
                    content={role.roleLabel}
                    textColor={textColor.gray}
                  />
                </Paragraph>
                <Divider />
              </Radio>
            </Col>
          ))}
        </Radio.Group>
      </Row>
    </Modal>
  );
};

export default SelectProfile;
