import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Skeleton, ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react';
import { locales } from '@config/locales';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useAppSelector } from '@lib/index';
import ComponentRoutes from '../_routes';
import { persister } from './store';
import ErrorFallback from './errorFallback';
import AuthZero from './authZero';

type Language = 'en' | 'es';

const App = () => {
  const { i18n: { language } } = useTranslation();
  const hotJarId = import.meta.env.VITE_HOTJAR_ID;
  const googleTagManagerId = import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID;
  const intercomAppId = import.meta.env.VITE_INTERCOM_APP_ID;
  const { currentUser } = useAppSelector((state) => state.account);
  const { email, firstName, lastName } = currentUser;
  const intercomFields = currentUser ? { email, name: `${firstName} ${lastName}` } : {};
  return (
    <ConfigProvider locale={locales[language as Language]}>
      <PersistGate loading={<Skeleton />} persistor={persister}>
        <Sentry.ErrorBoundary
          fallback={ErrorFallback as FallbackRender}
        >
          <Helmet>
            {/* Your script tags and meta information */}
            <script>
              {`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${hotJarId},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
            </script>

            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`}
            />
            <script>
              {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${googleTagManagerId}');
        `}
            </script>

            <script>
              {`var APP_ID = '${intercomAppId}' ; // Replace this with your workspace ID
       window.intercomSettings = {
          api_base: 'https://api-iam.intercom.io',
          app_id: APP_ID,
          ...${JSON.stringify(intercomFields)}
            };

        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
            </script>
          </Helmet>
          <AuthZero>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<ComponentRoutes />} />
              </Routes>
            </BrowserRouter>
          </AuthZero>
        </Sentry.ErrorBoundary>
      </PersistGate>
    </ConfigProvider>
  );
};

export default App;
