/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { CommunesApiProps } from './types';
import commune from './commune';

// Add Tag to the list of tags to be invalidated when the list of communes is updated
const communeTag = baseApi.enhanceEndpoints({ addTagTypes: [commune.tagType] });
const communeApi = communeTag.injectEndpoints({
  endpoints: (builder) => ({
    getCommunes: builder.query<CommunesApiProps, void>(commune.getEntities()),
  }),
  overrideExisting: false,
});

export const { useGetCommunesQuery } = communeApi;
