/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import healthCareProvider from './healthCareProvider';
import { HealthCareProvidersApiProps } from './types';

// Add Tag to the list of tags to be invalidated when the list of healthcare providers is updated
const healthCareProviderTag = baseApi.enhanceEndpoints(
  { addTagTypes: [healthCareProvider.tagType] },
);
const healthCareProviderApi = healthCareProviderTag.injectEndpoints({
  endpoints: (builder) => ({
    getHealthCareProviders: builder.query<HealthCareProvidersApiProps, void>(
      healthCareProvider.getEntities(),
    ),
  }),
  overrideExisting: false,
});

export const { useGetHealthCareProvidersQuery } = healthCareProviderApi;
