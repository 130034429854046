/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line consistent-return
export const parseJWT = (token: string) => {
  if (token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    } catch (e) {
      return null;
    }
  }
};
