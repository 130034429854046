import React, { useEffect, useRef } from 'react';
import {
  SubmitButton, Form, FormItem, Formik, FormikHelpers,
  Link, useNavigate, message, Row, Col, Typography, UserOutlined,
  InputTextField, InputPasswordField, LockOutlined, LeftCircleOutlined, useAppDispatch,
  useAppSelector, useTranslation,
} from '@lib/index';
import ReCAPTCHA from 'react-google-recaptcha';
import { clearState, loginUser } from '../../../_features/account/AccountSlice';
import { LoginFormProps } from '../types';
import { loginFormValidation } from '../schema/AuthFormValidation';
import { loginInitialValues } from '../schema/AuthDefaultValue';
import SelectProfile from '../SelectProfile';
import ChangePassword from './ChangePassword';

const LoginForm = () => {
  const { Title } = Typography;
  const dispatch = useAppDispatch();
  const environment = import.meta.env.VITE_NODE_ENV;
  const { currentUser, isError } = useAppSelector((state) => state.account);
  const { roleId, token, passwordChange } = currentUser;
  const isAuthenticated = token !== '' && roleId !== null && passwordChange === false;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const onSubmit = (
    values: LoginFormProps,
    { setSubmitting }: FormikHelpers<LoginFormProps>,
  ) => {
    try {
      const { email, password } = values;
      const captchaToken = reCaptchaRef.current?.getValue();
      if (!captchaToken && environment === 'production') {
        message.warning(t('login.reCaptcha'), 5);
        setSubmitting(false);
        return;
      }

      dispatch(loginUser({ email, password, recaptcha: captchaToken || '' }));
      reCaptchaRef.current?.reset();
    } catch (e) {
      message.error(t('login.loginError'), 2);
      dispatch(clearState());
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(clearState());
      const home = '/dashboard';
      navigate(home);
    }
    if (isError) {
      message.warning(
        t('login.loginError'),
        5,
      );
      dispatch(clearState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isAuthenticated, dispatch, navigate]);

  return (
    <div className="bg-login-bg bg-no-repeat bg-cover h-screen overflow-auto bg-center py-24 2xl:p-24 shadow-inner" data-cy="login-form">
      <Row align="middle" justify="center" className="mt-0">
        <Col xs={22} sm={18} md={12} lg={12} xl={6} className="mt-0">
          <Link to="/" className="text-white text-2xl">
            <LeftCircleOutlined className="text-white text-2xl pr-8" />
            {t('login.backToSite')}
          </Link>
          <Formik
            enableReinitialize
            initialValues={loginInitialValues}
            onSubmit={onSubmit}
            validationSchema={loginFormValidation}
          >
            <div className="p-6 sm:p-12 mt-4 h-full w-full bg-white">
              <Title type="secondary" className="text-center text-lg">
                {t('login.title')}
              </Title>
              <Form name="normal_login" className="login-form">
                <InputTextField
                  name="email"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t('login.email')}
                  size="large"
                  data-cy="login-form-email"
                />
                <InputPasswordField
                  name="password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={t('login.password')}
                  size="large"
                  data-cy="login-form-password"
                />
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY as string}
                />
                <FormItem name="wrapper" className="text-right">
                  <Link className="login-form-forgot" to="/reset-password">
                    {t('login.forgotPassword')}
                  </Link>
                </FormItem>
                <FormItem name="button">
                  <SubmitButton
                    block
                    size="large"
                    data-cy="login-form-submit"
                  >
                    {t('login.login')}
                  </SubmitButton>
                </FormItem>
              </Form>
            </div>
          </Formik>
          <div className="bg-logo-bg bg-no-repeat bg-center h-56 bg-auto" />
          <ChangePassword requestPasswordChange={passwordChange} />
          <SelectProfile />
        </Col>
      </Row>
    </div>
  );
};
export default LoginForm;
