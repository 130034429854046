/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { StencilsApiProps, StencilDocumentsApiProps } from './types';
import stencil from './stencil';

const stencilTag = baseApi.enhanceEndpoints({ addTagTypes: [stencil.tagType] });
const stencilApi = stencilTag.injectEndpoints({
  endpoints: (builder) => ({
    getStencils: builder.query<StencilsApiProps, number>(
      stencil.getEntitiesWithId('contractors'),
    ),
    getStencilsDocuments:
    builder.query<StencilDocumentsApiProps, number>(stencil.getStencilDocuments()),
  }),
  overrideExisting: false,
});

export const {
  useGetStencilsQuery,
  useGetStencilsDocumentsQuery,
} = stencilApi;
