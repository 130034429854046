import React, { useEffect } from 'react';
import {
  Link, useNavigate, message, Row, Col, Typography,
  LeftCircleOutlined, useAppDispatch,
  useAppSelector, useTranslation,
  BasicButtonBG,
  roleService,
} from '@lib/index';
import { useAuth0 } from '@auth0/auth0-react';
import {
  assignRole,
  clearState,
  getRolePermissions,
} from '@features/account/AccountSlice';

const Auth0Login = () => {
  const { Title } = Typography;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentUser } = useAppSelector((state) => state.account);
  const { kitchen } = currentUser;
  const { t } = useTranslation();
  const { useLoginRoleWithTokenMutation } = roleService;
  const [loginRoleWithToken] = useLoginRoleWithTokenMutation();

  const {
    isAuthenticated, error, loginWithPopup, getAccessTokenSilently,
  } = useAuth0();

  const handleTokenGeneration = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await loginRoleWithToken({ token }).unwrap();
      await dispatch(assignRole(response));
      await dispatch(getRolePermissions(response.token as string));
    } catch {
      message.warning(
        t('login.loginError'),
        5,
      );
      return;
    }
    dispatch(clearState());
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleTokenGeneration();
    }

    if (error) {
      message.warning(
        t('login.loginError'),
        5,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, error]);

  useEffect(() => {
    if (isAuthenticated && kitchen) {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, kitchen]);

  return (
    <div className="bg-login-bg bg-no-repeat bg-cover h-screen overflow-auto bg-center py-24 2xl:p-24 shadow-inner" data-cy="login-form">
      <Row align="middle" justify="center" className="mt-0">
        <Col xs={22} sm={18} md={12} lg={12} xl={6} className="mt-0">
          <Link to="/" className="text-white text-2xl">
            <LeftCircleOutlined className="text-white text-2xl pr-8" />
            {t('login.backToSite')}
          </Link>
          <div className="p-6 sm:p-12 mt-4 h-full w-full bg-white">
            <Title type="secondary" className="text-center text-lg">
              {t('login.title')}
            </Title>
            <Row>
              <Col xs={24}>
                <BasicButtonBG
                  size="large"
                  className="mt-4"
                  data-cy="login-form-auth0"
                  onClick={loginWithPopup as (React.MouseEventHandler<HTMLElement> & (() => void))}
                  buttonColorType="default"
                  text="Login with Auth0"
                  loading={false}
                  type="primary"

                />
              </Col>

            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Auth0Login;
