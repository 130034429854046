/* eslint-disable react/require-default-props */
import React from 'react';
import { Typography } from 'antd';
import { DetailTextProps } from './types';

const DetailText = ({
  content,
  header,
  textColor,
}: DetailTextProps) => {
  const { Text, Paragraph } = Typography;
  return (
    <>
      <Paragraph className="text-gray-500 text-xs xs:text-xs sm:text-xs md:text-xs lg:text-sm xl:text-sm 2xl:text-sm">{header}</Paragraph>
      <Text className={`font-semibold ${textColor} text-sm xs:text-sm sm:text-sm md:text-base lg:text-base xl:text-lg 2xl:text-lg`}>{content || '-'}</Text>
    </>
  );
};

export default DetailText;
