/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
// import { VehicleCategoryApiProps } from './types';
import vehicleCategory from './vehiclecategory';
import { VehicleCategoriesApiProps } from './types';

// Add Tag to the list of tags to be invalidated when the list of countries is updated
const vehicleCategoryTag = baseApi.enhanceEndpoints({ addTagTypes: [vehicleCategory.tagType] });
const vehicleCategoryApi = vehicleCategoryTag.injectEndpoints({
  endpoints: (builder) => ({
    getVehicleCategories: builder.query<VehicleCategoriesApiProps, void>(
      vehicleCategory.getEntities(),
    ),
  }),
  overrideExisting: false,
});

export const { useGetVehicleCategoriesQuery } = vehicleCategoryApi;
