/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import company from './company';
import { CompaniesApiProps } from './types';

// Add Tag to the list of tags to be invalidated when the list of companies is updated
const companyTag = baseApi.enhanceEndpoints({ addTagTypes: [company.tagType] });
const companyApi = companyTag.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<CompaniesApiProps, void>(company.getEntities()),
  }),
  overrideExisting: false,
});

export const { useGetCompaniesQuery } = companyApi;
