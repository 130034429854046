export const loginInitialValues = {
  email: '',
  password: '',
};

export const passwordChangeInitialValues = {
  email: '',
};

export const passwordResetFormWithTokenInitialValues = {
  password: '',
  passwordConfirmation: '',
};
