/* eslint-disable import/prefer-default-export */
import { PERMISSIONS } from '@config/index';
import { useAppSelector } from './redux.hooks';

export const useGetPermissions = () => {
  const { currentUser } = useAppSelector((state) => state.account);
  const { role } = currentUser;
  const permissions = [...PERMISSIONS[role], ...currentUser.permissions];
  return permissions;
};
