/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import {
  PersonnelApiProps,
  PersonnelParams,
  PersonApiProps,
} from './types';
import personnel from './personnel';

const personnelTag = baseApi.enhanceEndpoints({ addTagTypes: [personnel.tagType] });
const personnelApi = personnelTag.injectEndpoints({
  endpoints: (builder) => ({
    getClerks: builder.query<PersonnelApiProps, PersonnelParams>(
      personnel.getClerks(),
    ),
    getClerk: builder.query<PersonApiProps, number>(personnel.getEntity()),
    addClerk: builder.mutation<
      PersonApiProps,
      Partial<PersonApiProps>
    >(personnel.addEntity('sections')),
    updateClerk: builder.mutation<
      void,
      Pick<PersonApiProps, 'id'> & Partial<PersonApiProps>
    >(personnel.updateEntity()),
  }),
  overrideExisting: false,
});

export const {
  useGetClerksQuery,
  useGetClerkQuery,
  useAddClerkMutation,
  useUpdateClerkMutation,
} = personnelApi;
