/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { HelpCentersApiProps, ZendeskFormProps, ZendeskOptionsApiProps } from './types';
import helpCenter from './helpcenter';

const helpCenterTag = baseApi.enhanceEndpoints({ addTagTypes: [helpCenter.tagType] });
const helpCenterAPi = helpCenterTag.injectEndpoints({
  endpoints: (builder) => ({
    getHelpCenters: builder.query<HelpCentersApiProps, number>(
      helpCenter.getEntitiesWithId('sites'),
    ),
    getMetaContractorHelpCenters: builder.query<HelpCentersApiProps, number>(
      helpCenter.getEntitiesWithId('meta-contractors'),
    ),
    getZendeskOptions: builder.query<ZendeskOptionsApiProps, void>(helpCenter.getZendeskOptions()),
    createZendeskTocket: builder.mutation<void, ZendeskFormProps>(
      helpCenter.createZendeskTicket(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetHelpCentersQuery,
  useGetMetaContractorHelpCentersQuery,
  useGetZendeskOptionsQuery,
  useCreateZendeskTocketMutation,
} = helpCenterAPi;
