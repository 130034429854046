import React from 'react';
import { map } from 'lodash';
import {
  Tag, Typography, List, Popover,
} from 'antd';
import humps from 'lodash-humps-ts';
import { useTranslation } from 'react-i18next';
import { DocumentStatusProps, DocumentStatusDataProps } from './types';

const DocumentStatus = ({ documentStatus }: { documentStatus?: DocumentStatusProps }) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const docStatus = humps(documentStatus) as DocumentStatusDataProps;
  const docStatusData = [
    { color: 'success', status: docStatus?.approved, text: t('job.jobStatus.accepted') },
    { color: 'error', status: docStatus?.rejected, text: t('job.jobStatus.rejected') },
    { color: 'warning', status: docStatus?.pending, text: t('job.jobStatus.pending') },
    { color: 'gray', status: docStatus?.expired, text: t('job.jobStatus.expired') },
    { color: '', status: docStatus?.notUploaded, text: t('job.jobStatus.notUploaded') },
  ];

  const docStatusList = map(
    docStatusData,
    (doc) => (<Tag key={doc.text} color={doc.color}>{doc.status}</Tag>),
  );
  const docStatusPopover = (
    <List
      dataSource={docStatusData}
      renderItem={(doc) => (
        <List.Item>
          <Tag color={doc.color}>{doc.status}</Tag>
          <Text>{doc.text}</Text>
        </List.Item>
      )}
      itemLayout="vertical"
    />
  );
  return (
    <Popover
      title={t('job.documentSummary')}
      content={docStatusPopover}
      placement="bottom"

    >
      {docStatusList}
    </Popover>

  );
};

DocumentStatus.defaultProps = {
  documentStatus: {
    approved: 0,
    rejected: 0,
    pending: 0,
    expired: 0,
    notUploaded: 0,
  },
};

export default DocumentStatus;
