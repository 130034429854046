/* eslint-disable class-methods-use-this */
import BaseService from '../base';
import { ZendeskFormProps, ZendeskOptionsApiProps } from './types';

class HelpCenter extends BaseService {
  getZendeskOptions() {
    return {
      query: () => '/zendesk-options',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: ZendeskOptionsApiProps) => [{ type: this.tagType }],
    };
  }

  createZendeskTicket() {
    return {
      query: (params: ZendeskFormProps) => ({
        url: '/zendesk-tickets',
        method: 'POST',
        body: params,
      }),
    };
  }
}

export default new HelpCenter('help-centers', 'HelpCenter');
