import React from 'react';
import { Formik } from 'formik';
import {
  Row, Col, Typography,
} from 'antd';
import { SubmitButton, Form } from 'formik-antd';
import InputPasswordField from '@components/atoms/input-password-field';
import Modal from '@components/molecules/modal';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import BasicButtonBG from '@components/atoms/basic-button-bg';
import { textColor } from '@helpers/index';
import ReCAPTCHA from 'react-google-recaptcha';
import { PasswordChangeProps } from './types';

const PasswordChange = ({
  modalVisibility, onCancel, onSubmit,
  changePasswordInitialValues, changePasswordFormValidation,
  reCaptchaRef,
}: PasswordChangeProps) => {
  const { Title } = Typography;
  const { t } = useTranslation();

  const footer = (
    <Form>
      <Row gutter={[16, 16]} justify="end">
        <BasicButtonBG text={t('account.cancel')} loading={false} onClick={onCancel} />
        <SubmitButton>{t('account.submit')}</SubmitButton>
      </Row>
    </Form>
  );

  return (

    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Formik
        initialValues={changePasswordInitialValues}
        onSubmit={onSubmit}
        validationSchema={changePasswordFormValidation}
      >
        <Modal
          title={<Title className={`text-xl ${textColor.darkBlue}`}>{t('account.changePassword')}</Title>}
          closeIcon={<CloseOutlined className="text-2xl" />}
          visible={modalVisibility}
          onCancel={onCancel}
          okText={t('account.submit')}
          displayButtons={false}
          footer={footer}
          width={800}
          cancelText={t('account.cancel')}
        >
          <Form layout="vertical">
            <Row gutter={[0, 20]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row gutter={[0, 20]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <InputPasswordField
                      name="currentPassword"
                      size="middle"
                      placeholder={t('account.currentPassword')}
                      label={t('account.currentPasswordLabel')}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <InputPasswordField
                      name="password"
                      size="middle"
                      placeholder={t('account.password')}
                      label={t('account.passwordLabel')}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <InputPasswordField
                      name="passwordConfirmation"
                      size="middle"
                      placeholder={t('account.passwordLabel')}
                      label={t('account.passwordConfirmationLabel')}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <ReCAPTCHA
                      ref={reCaptchaRef}
                      sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY as string}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Formik>
    </Col>
  );
};

export default PasswordChange;
