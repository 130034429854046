/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { AnnouncementsApiProps } from './types';
import announcement from './announcement';

// Add Tag to the list of tags to be invalidated when the list of countries is updated
const announcementTag = baseApi.enhanceEndpoints({
  addTagTypes: [announcement.tagType],
});
const announcementAPi = announcementTag.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query<AnnouncementsApiProps, number>(
      announcement.getEntitiesWithId('sites'),
    ),
  }),
  overrideExisting: false,
});

export const { useGetAnnouncementsQuery } = announcementAPi;
