/* eslint-disable class-methods-use-this */
import { providesList } from '@helpers/index';
import BaseService from '../base';
import { DocumentAlertsParamsProps, DocumentAlertsApiProps } from './types';

class DocumentAlert extends BaseService {
  getDocumentAlerts() {
    return {
      query: ({ siteId, ...params }: DocumentAlertsParamsProps) => ({
        url: `/sites/${siteId}/document-alerts`,
        params,
      }),
      providesTags: (result?: DocumentAlertsApiProps) => providesList(result?.alerts, 'DocumentAlert'),
    };
  }

  getPendingAlerts() {
    return {
      query: () => ({
        url: 'users/current/pending_alerts',
      }),
      providesTags: () => [{ type: this.tagType }],
    };
  }
}

export default new DocumentAlert('doc-alerts', 'DocumentAlert');
