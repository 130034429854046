import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

const AuthZero = ({ children }: { children: JSX.Element}) => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const auth0Enabled = import.meta.env.VITE_AUTH0_ENABLED === 'true';
  return (
    auth0Enabled ? (
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: 'https://newsoftys.ksec.cl/',
          scope: 'openid profile email',
        }}
      >
        {children}
      </Auth0Provider>
    )
      : children
  );
};

export default AuthZero;
