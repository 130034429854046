/* eslint-disable class-methods-use-this */
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { providesList } from '@helpers/index';

type BaseServiceProps = {
  getEntity: (secondaryUrl?: string) => void;
  getEntities: () => void;
  getEntitiesWithId: (secondaryUrl?: string) => void;
  updateEntity: (secondaryUrl?: string) => void;
  addEntity: (secondaryUrl?: string) => void;
  deleteEntity: (secondaryUrl?: string) => void;
};

abstract class BaseService implements BaseServiceProps {
  // eslint-disable-next-line no-useless-constructor
  constructor(public url: string, public tagType: string) {}

  getEntity(secondaryUrl?: string) {
    return {
      query: (id: number) => this.checkUrl(id, secondaryUrl),
      providesTags: (
        result: any | undefined,
        error: FetchBaseQueryError | undefined,
        id: number,
      ) => [{ type: this.tagType, id }],
    };
  }

  getEntities() {
    return {
      query: () => this.url,
      providesTags: (result: any[] | undefined) => providesList(result, this.tagType),
    };
  }

  getEntitiesWithId(secondaryUrl?: string, params?: string) {
    return {
      query: (id: number) => this.checkUrl(id, secondaryUrl, params),
      providesTags: (result: any[] | undefined) => providesList(result, this.tagType),
    };
  }

  updateEntity(secondaryUrl?: string) {
    return {
      query: ({ id, ...patch }: { id: number }) => ({
        url: this.checkUrl(id, secondaryUrl),
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (
        result: void | undefined,
        error: FetchBaseQueryError | undefined,
        { id }: { id: number },
      ) => [{ type: this.tagType, id }],
    };
  }

  addEntity(secondaryUrl?: string) {
    return {
      query: <T>({ id, ...body }: { id: number; body: T }) => ({
        url: this.checkUrl(id, secondaryUrl),
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }],
    };
  }

  deleteEntity(secondaryUrl?: string) {
    return {
      query: (id: number) => ({
        url: this.checkUrl(id, secondaryUrl),
        method: 'DELETE',
      }),
      invalidatesTags: (
        result: { success: boolean; id: number } | undefined,
        error: FetchBaseQueryError | undefined,
        id: number,
      ) => [{ type: this.tagType, id: 'LIST' }],
    };
  }

  sendReport(secondaryUrl: string) {
    // TODO: move the report to a worker service
    return {
      query: <T>(body: T) => ({
        url: secondaryUrl,
        method: 'POST',
        body,
      }),
    };
  }

  deleteEntityAll(secondaryUrl?: string) {
    return {
      query: (id: number) => ({
        url: this.checkUrl(id, secondaryUrl),
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }],
    };
  }

  checkUrl(id: number, secondaryUrl?: string, params = '') {
    return secondaryUrl ? `${secondaryUrl}/${id}/${this.url}${params}` : `${this.url}/${id}`;
  }
}

export default BaseService;
