/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import {
  StaffingContractorStatApiProps,
  AreaSectionStatsApiProps,
  RegularContractorStatApiProps,
  StoreSectionStatsApiProps,
  CorpSiteStatsParams,
  CorpSiteStatsApiProps,
} from './types';
import stat from './stat';

const statTag = baseApi.enhanceEndpoints({ addTagTypes: [stat.tagType] });

const statApi = statTag.injectEndpoints({
  endpoints: (builder) => ({
    getStaffingContractorStats: builder.query<
      StaffingContractorStatApiProps,
      number
    >(stat.getStaffingContractorDashboard()),
    getRegularContractorStats: builder.query<RegularContractorStatApiProps, number>(
      stat.getRegularContractorDashboard(),
    ),
    getAreaSectionStats: builder.query<AreaSectionStatsApiProps, number>(
      stat.getAreaSectionDashboard(),
    ),
    getStoreSectionStats: builder.query<StoreSectionStatsApiProps, number>(
      stat.getStoreSectionDashboard(),
    ),
    getCorpSiteStats: builder.query<CorpSiteStatsApiProps, CorpSiteStatsParams>(
      stat.getCorpSiteDashboard(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetStaffingContractorStatsQuery,
  useGetRegularContractorStatsQuery,
  useGetAreaSectionStatsQuery,
  useGetStoreSectionStatsQuery,
  useGetCorpSiteStatsQuery,
} = statApi;
