/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { UserApiProps, UpdatePasswordParams, UpdatePasswordApi } from './types';
import user from './user';

// Add Tag to the list of tags to be invalidated when the list of countries is updated
const userTag = baseApi.enhanceEndpoints({ addTagTypes: [user.tagType] });
const userApi = userTag.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserApiProps, number>(user.getEntity()),
    updateUser: builder.mutation<void, Pick<UserApiProps, 'id'> & Partial<UserApiProps>>(
      user.updateEntity(),
    ),
    updatePassword: builder.mutation<UpdatePasswordApi, UpdatePasswordParams>(
      user.updatePassword(),
    ),
    updateUserPassword: builder.mutation<UpdatePasswordApi, UpdatePasswordParams>(
      user.updateUserPassword(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
  useUpdateUserPasswordMutation,
} = userApi;
