import { providesList } from '@helpers/providerList';
import BaseService from '../base';
import { PersonnelApiProps, PersonnelParams } from './types';

class Personnel extends BaseService {
  getClerks() {
    return {
      query: ({ sectionId, ...params }: PersonnelParams) => ({
        url: `/sections/${sectionId}/clerks`,
        params,
      }),
      providesTags: (result?: PersonnelApiProps) => providesList(result?.clerks, this.tagType),
    };
  }
}

export default new Personnel('clerks', 'Personnel');
