/* eslint-disable import/prefer-default-export */
export const textColor = {
  darkBlue: 'text-blue-900',
  lightBlue: 'text-blue-500',
  blue: 'text-blue-600',
  maroon: 'text-red-700',
  orange: 'text-orange-400',
  lightOrange: 'text-orange-50',
  green: 'text-green-500',
  lightGreen: 'text-green-50',
  red: 'text-red-500',
  darkRed: 'text-red-700',
  lightRed: 'text-red-50',
  gold: 'text-yellow-600',
  lightGold: 'text-yellow-300',
  gray: 'text-gray-600',
  darkGrey: 'text-gray-700',
  lightGrey: 'text-gray-400',
  softGray: 'text-gray-200',
  white: 'text-white',
  sky: 'text-sky-500',
  lightSky: 'text-sky-50',
  lightYellow: 'text-yellow-50',
};

export const bgColor = {
  darkBlue: 'bg-blue-900',
  lightBlue: 'bg-blue-100',
  blue: 'bg-blue-500',
  maroon: 'bg-red-700',
  orange: 'bg-orange-500',
  lightOrange: 'bg-orange-50',
  green: 'bg-green-500',
  lightGreen: 'bg-green-50',
  red: 'bg-red-500',
  darkRed: 'bg-red-700',
  lightRed: 'bg-red-50',
  gold: 'bg-yellow-600',
  gray: 'bg-gray-600',
  darkGrey: 'bg-gray-700',
  lightGrey: 'bg-gray-400',
  softGray: 'bg-gray-200',
  whiteGray: 'bg-gray-100',
  white: 'bg-white',
  sky: 'bg-sky-500',
  lightSky: 'bg-sky-50',
  lightYellow: 'bg-yellow-50',
};

export const statusColor = {
  darkBlue: '#194682',
  lightBlue: '#7AD2FF',
  maroon: '#B64D51',
  green: '#52c41a',
  red: '#FF0007',
  darkGrey: '#585858',
  black: '#000000',
  gold: '#E8D576',
  orange: '#FFB015',
  lightGrey: '#F2F2F2',
  grey: '#979797',
  indianRed: '#CC444A',
  blue: '#194682',
};

export const docStatusColor = (status: string) => {
  const documentStatusColor = {
    approved: `${bgColor.lightGreen} ${textColor.green}`,
    rejected: `${bgColor.lightRed} ${textColor.red}`,
    digitalReview: `${bgColor.lightBlue} ${textColor.blue}`,
    expired: `${bgColor.softGray} ${textColor.gray}`,
    notLoaded: `border border-black ${textColor.gray}`,
    loaded: `${bgColor.lightSky} ${textColor.sky}`,
    deactivated: `${bgColor.red} ${textColor.darkRed}`,
  };
  return documentStatusColor[status as keyof typeof documentStatusColor];
};
