/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { CountriesApiProps } from './types';
import country from './country';

// Add Tag to the list of tags to be invalidated when the list of countries is updated
const countryTag = baseApi.enhanceEndpoints({ addTagTypes: [country.tagType] });
const countryAPi = countryTag.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<CountriesApiProps, void>(country.getEntities()),
  }),
  overrideExisting: false,
});

export const { useGetCountriesQuery } = countryAPi;
