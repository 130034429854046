/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import { filter, some } from 'lodash';
import { ROLES, SCOPES } from '@config/index';
import { assignPermissions } from '@features/account/AccountSlice';
import { useSwitchEmployers } from './useSwitchEmployers';
import { useGetPermissions } from './useGetPermissions';
import { ContractorData } from './types';
import { useAppDispatch, useAppSelector } from './redux.hooks';

export const usePermissions = () => {
  const { siteEmployers } = useSwitchEmployers();
  const { currentUser } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const permissions = useGetPermissions();
  const canViewStaffingContractorF30Page = siteEmployers?.some(
    (siteEmployer) => siteEmployer.config?.f30?.includes(SCOPES.canViewStaffingContractorF30Page),
  );
  const canViewRegularContractorF30Page = siteEmployers?.some(
    (siteEmployer) => siteEmployer.config?.f30?.includes(SCOPES.canViewRegularContractorF30Page),
  );
  const canStaffingContractorAreaViewInvoicesPage = permissions.includes(
    SCOPES.canViewInvoicesPage,
  );
  const canStaffingContractorAreaViewF30Page = permissions.includes(
    SCOPES.canViewStaffingContractorF30Page,
  );
  const canRegularContractorAreaViewF30Page = permissions.includes(
    SCOPES.canViewRegularContractorF30Page,
  );
  const canStaffingContractorViewInvoicesPage = siteEmployers?.some(
    (siteEmployer) => siteEmployer.config?.invoice?.includes(SCOPES.canViewInvoicesPage),
  );
  const employersWithVehiclesJobGroups = filter(siteEmployers, (siteEmployer) => (
    siteEmployer.jobFields.includes('vehicle_groups')
  ));
  const hasVehicleGroupsJob = some(siteEmployers, (employer) => (
    employer.jobFields.includes('vehicle_groups')
  ));
  const canViewAreaSectionVehiclesPage = permissions.includes(
    SCOPES.canViewAreaSectionVehiclesPage,
  );
  const hasVehicleGroupsJobContractor = (contractor: ContractorData) => (
    contractor?.jobFields?.includes('vehicle_groups')
  );
  const employersF30ConfigActivated = filter(siteEmployers, (employer) => {
    if (currentUser.role === ROLES.staffingContractor) {
      return employer.config?.f30?.includes(SCOPES.canViewStaffingContractorF30Page);
    }
    if (currentUser.role === ROLES.regularContractor) {
      return employer.config?.f30?.includes(SCOPES.canViewRegularContractorF30Page);
    }
    return true;
  });
  const employersInvoiceConfigActivated = filter(siteEmployers, (employer) => (
    employer.config?.invoice?.includes(SCOPES.canViewInvoicesPage)
  ));
  const handleContractorChangePermissions = (contractorId: number) => {
    const currentEmployerPermissions = siteEmployers?.find((siteEmployer) => (
      siteEmployer.id === contractorId
    ));
    if (siteEmployers?.length > 1) {
      dispatch(assignPermissions({ ...currentEmployerPermissions?.config }));
    }
  };
  return {
    hasVehicleGroupsJob,
    canViewStaffingContractorF30Page,
    canViewRegularContractorF30Page,
    canStaffingContractorAreaViewInvoicesPage,
    employersWithVehiclesJobGroups,
    canViewAreaSectionVehiclesPage,
    hasVehicleGroupsJobContractor,
    employersF30ConfigActivated,
    handleContractorChangePermissions,
    employersInvoiceConfigActivated,
    canStaffingContractorViewInvoicesPage,
    canStaffingContractorAreaViewF30Page,
    canRegularContractorAreaViewF30Page,
  };
};
