/* eslint-disable max-len */
import React, { lazy, Suspense, useEffect } from 'react';
import {
  Route,
  Routes,
  Skeleton,
  ROLE,
  eventBus,
  useTranslation,
  dayjs,
  Navigate,
} from '@lib/index';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import SwitchRole from '../_views/auth/SelectProfile/SwitchRole';
import Layout from '../_layout/Layout';
import PrivateRoute from './PrivateRoute';
import LoginForm from '../_views/auth/LoginForm';
import PasswordResetForm from '../_views/auth/PasswordResetForm';
import { persister } from '../_app/store';
import PasswordResetFormWithToken from '../_views/auth/PasswordResetForm/PasswordResetWithToken';
import Logout from '../_views/auth/Logout';
import '../_i18n/config';
import 'dayjs/locale/es';

const DashboardList = lazy(() => import('../_views/dashboard/DashboardList'));
const NotFound = lazy(() => import('../_views/pages/notfound/NotFound'));
const WorkerList = lazy(() => import('../_views/worker/WorkerList'));
const WorkerForm = lazy(() => import('../_views/worker/WorkerForm'));
const AccountDetail = lazy(() => import('../_views/account/AccountDetail'));
const AccountForm = lazy(() => import('../_views/account/AccountForm'));
const HelpCenterDetail = lazy(() => import('../_views/helpcenter/HelpCenterDetail'));
const HelpCenterVideoList = lazy(
  () => import('../_views/helpcenter/HelpCenterDetail/HelpCenterVideoList'),
);
const HelpCenterFileList = lazy(
  () => import('../_views/helpcenter/HelpCenterDetail/HelpCenterFileList'),
);
const AccessList = lazy(() => import('../_views/access/AccessList'));
const JobList = lazy(() => import('../_views/job/JobList'));
const JobDetail = lazy(() => import('../_views/job/JobDetail'));
const DocumentList = lazy(() => import('../_views/documentation/DocumentList'));
const DocumentAlert = lazy(() => import('../_views/documentalert/DocumentAlertList'));
const DocumentDetail = lazy(() => import('../_views/documentation/DocumentList/DocumentTab/DocumentDetail'));
const Setting = lazy(() => import('../_views/setting'));
const SettingAccountForm = lazy(() => import('../_views/setting/AccountTab/AccountForm'));
const ShiftForm = lazy(() => import('../_views/setting/ShiftTab/ShiftForm'));
const PlacementForm = lazy(() => import('../_views/setting/PlacementTab/PlacementForm'));
const MetaContractorSubcategoryForm = lazy(() => import('../_views/setting/MetaContractorSubcategoryTab/MetaContractorSubcategoryForm'));
const UserList = lazy(() => import('../_views/user/UserList'));
const UserForm = lazy(() => import('../_views/user/UserForm'));
const UserDetail = lazy(() => import('../_views/user/UserDetail'));
const SiteWorkerDetail = lazy(() => import('../_views/siteworker/SiteWorkerDetail'));
const SiteWorkerList = lazy(() => import('../_views/siteworker/SiteWorkerList'));
const SiteWorkerForm = lazy(() => import('../_views/siteworker/SiteWorkerForm'));
const Unauthorized = lazy(() => import('../_views/pages/unauthorized/Unauthorized'));
const PersonnelList = lazy(() => import('../_views/personnel/PersonnelList'));
const PersonnelForm = lazy(() => import('../_views/personnel/PersonnelForm'));
const ContractorList = lazy(() => import('../_views/contractor/ContractorList'));
const ContractorDetail = lazy(() => import('../_views/contractor/ContractorDetail'));
const ContractorForm = lazy(() => import('../_views/contractor/ContractorForm'));
const ContractorUserForm = lazy(() => import('../_views/contractor/ContractorUserForm'));
const ContractorVehicleForm = lazy(() => import('../_views/documentation/ContractorVehicleForm'));

const App = () => {
  const { i18n: { language } } = useTranslation();
  const auth0Enabled = import.meta.env.VITE_AUTH0_ENABLED === 'true';
  const { logout } = useAuth0();
  dayjs.locale(language);
  const handleLogOut = async () => {
    if (auth0Enabled) await logout();
    persister.purge();
    localStorage.clear();
  };

  useEffect(() => {
    eventBus.on('logout', handleLogOut);
    return () => {
      eventBus.remove('logout', handleLogOut);
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="login" replace />} />

      {/* Public Routes */}
      <Route path="login" element={<LoginForm />} />
      <Route path="reset-password" element={<PasswordResetForm />} />
      <Route path="recover-password/:token" element={<PasswordResetFormWithToken />} />
      <Route path="logout" element={<Logout />} />

      {/* Switch Role */}
      <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.area_section, ROLE.store_section, ROLE.regular_contractor, ROLE.staffing_contractor, ROLE.corp_site, ROLE.administrator_section]} />}>
        <Route path="/switching/:id" element={<SwitchRole />} />
      </Route>

      <Route path="/" element={<Layout />}>

        {/* Private Routes */}
        <Route path="login" element={<LoginForm />} />

        {/* Dashboard */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.regular_contractor, ROLE.corp_site, ROLE.area_section, ROLE.store_section]} />}>
          <Route path="dashboard" element={<Suspense fallback={<Skeleton />}><DashboardList /></Suspense>} />
        </Route>

        {/* Worker */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor]} />}>
          <Route path="workers" element={<Suspense fallback={<Skeleton />}><WorkerList /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor]} />}>
          <Route path="workers/new" element={<Suspense fallback={<Skeleton />}><WorkerForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor]} />}>
          <Route path="workers/:id/edit" element={<Suspense fallback={<Skeleton />}><WorkerForm /></Suspense>} />
        </Route>

        {/* Account */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.area_section, ROLE.regular_contractor, ROLE.store_section]} />}>
          <Route path="accounts" element={<Suspense fallback={<Skeleton />}><AccountDetail /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.area_section, ROLE.regular_contractor, ROLE.store_section]} />}>
          <Route path="accounts/:id/edit" element={<Suspense fallback={<Skeleton />}><AccountForm /></Suspense>} />
        </Route>

        {/* Help Center */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.regular_contractor, ROLE.area_section, ROLE.store_section]} />}>
          <Route path="help-center" element={<Suspense fallback={<Skeleton />}><HelpCenterDetail /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.regular_contractor, ROLE.area_section, ROLE.store_section]} />}>
          <Route path="help-center/videos" element={<Suspense fallback={<Skeleton />}><HelpCenterVideoList /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.regular_contractor, ROLE.area_section, ROLE.store_section]} />}>
          <Route path="help-center/files" element={<Suspense fallback={<Skeleton />}><HelpCenterFileList /></Suspense>} />
        </Route>

        {/* Access */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.area_section, ROLE.regular_contractor]} />}>
          <Route path="workers-accesses" element={<Suspense fallback={<Skeleton />}><AccessList /></Suspense>} />
        </Route>

        {/* Job */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.area_section, ROLE.regular_contractor, ROLE.store_section, ROLE.administrator_section]} />}>
          <Route path="jobs" element={<Suspense fallback={<Skeleton />}><JobList /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.regular_contractor, ROLE.area_section, ROLE.store_section, ROLE.administrator_section]} />}>
          <Route path="jobs/:id" element={<Suspense fallback={<Skeleton />}><JobDetail /></Suspense>} />
        </Route>

        {/* Contractor */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.area_section, ROLE.administrator_section, ROLE.corp_site, ROLE.store_section]} />}>
          <Route path="contractors" element={<Suspense fallback={<Skeleton />}><ContractorList /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.area_section, ROLE.administrator_section, ROLE.corp_site, ROLE.store_section]} />}>
          <Route path="contractors/new" element={<Suspense fallback={<Skeleton />}><ContractorForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.area_section, ROLE.administrator_section, ROLE.corp_site, ROLE.store_section]} />}>
          <Route path="contractors/:id" element={<Suspense fallback={<Skeleton />}><ContractorDetail /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site, ROLE.store_section]} />}>
          <Route path="contractors/:id/users/new" element={<Suspense fallback={<Skeleton />}><ContractorUserForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.area_section, ROLE.administrator_section, ROLE.store_section]} />}>
          <Route path="contractors/:id/edit" element={<Suspense fallback={<Skeleton active />}><ContractorForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.area_section]} />}>
          <Route path="contractors/:id/vehicles/new" element={<Suspense fallback={<Skeleton />}><ContractorVehicleForm /></Suspense>} />
        </Route>

        {/* Document */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.regular_contractor]} />}>
          <Route path="documentations" element={<Suspense fallback={<Skeleton />}><DocumentList /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.area_section, ROLE.regular_contractor]} />}>
          <Route path="documentations/:id" element={<Suspense fallback={<Skeleton />}><DocumentDetail /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.regular_contractor]} permission="regularContractorF30" />}>
          <Route path="f30" element={<Suspense fallback={<Skeleton />}><DocumentList /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.regular_contractor]} />}>
          <Route path="contractors/vehicles/new" element={<Suspense fallback={<Skeleton />}><ContractorVehicleForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.area_section]} />}>
          <Route path="contractors/vehicles/:id/edit" element={<Suspense fallback={<Skeleton />}><ContractorVehicleForm /></Suspense>} />
        </Route>

        {/* Alert */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.staffing_contractor, ROLE.area_section]} />}>
          <Route path="alerts" element={<Suspense fallback={<Skeleton />}><DocumentAlert /></Suspense>} />
        </Route>

        {/* Settings */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site]} />}>
          <Route path="settings" element={<Suspense fallback={<Skeleton />}><Setting /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site]} />}>
          <Route path="/settings/accounts/:id/edit" element={<Suspense fallback={<Skeleton />}><SettingAccountForm /></Suspense>} />
        </Route>

        {/* Shift */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site]} />}>
          <Route path="shifts/new" element={<Suspense fallback={<Skeleton />}><ShiftForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site]} />}>
          <Route path="shifts/:id/edit" element={<Suspense fallback={<Skeleton />}><ShiftForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site]} />}>
          <Route path="/placements/new" element={<Suspense fallback={<Skeleton />}><PlacementForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site]} />}>
          <Route path="/placements/:id/edit" element={<Suspense fallback={<Skeleton />}><PlacementForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site]} />}>
          <Route path="/meta-contractor-subcategories/new" element={<Suspense fallback={<Skeleton />}><MetaContractorSubcategoryForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site]} />}>
          <Route path="/meta-contractor-subcategories/:id/edit" element={<Suspense fallback={<Skeleton />}><MetaContractorSubcategoryForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site]} />}>
          <Route path="/users" element={<Suspense fallback={<Skeleton />}><UserList /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site, ROLE.area_section]} />}>
          <Route path="/users/new" element={<Suspense fallback={<Skeleton />}><UserForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site, ROLE.area_section]} />}>
          <Route path="/users/:id/edit" element={<Suspense fallback={<Skeleton />}><UserForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site, ROLE.area_section]} />}>
          <Route path="/users/:id" element={<Suspense fallback={<Skeleton />}><UserDetail /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site, ROLE.area_section, ROLE.administrator_section]} />}>
          <Route path="/site-workers/:id" element={<Suspense fallback={<Skeleton />}><SiteWorkerDetail /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site, ROLE.area_section]} />}>
          <Route path="/site-workers" element={<Suspense fallback={<Skeleton />}><SiteWorkerList /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.corp_site, ROLE.area_section]} />}>
          <Route path="/site-workers/:id/edit" element={<Suspense fallback={<Skeleton />}><SiteWorkerForm /></Suspense>} />
        </Route>

        {/* Personnel */}
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.area_section, ROLE.store_section]} permission="personnel" />}>
          <Route path="/personnel" element={<Suspense fallback={<Skeleton />}><PersonnelList /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.area_section, ROLE.store_section]} permission="personnel" />}>
          <Route path="/personnel/new" element={<Suspense fallback={<Skeleton />}><PersonnelForm /></Suspense>} />
        </Route>
        <Route element={<PrivateRoute roles={[ROLE.admin, ROLE.area_section, ROLE.store_section]} permission="personnel" />}>
          <Route path="/personnel/:id/edit" element={<Suspense fallback={<Skeleton />}><PersonnelForm /></Suspense>} />
        </Route>

        {/* unauthorized */}
        <Route path="unauthorized" element={<Suspense fallback={<Skeleton />}><Unauthorized /></Suspense>} />

        {/* Catch all route */}
        <Route path="*" element={<Suspense fallback={<Skeleton />}><NotFound /></Suspense>} />
      </Route>
    </Routes>
  );
};

export default Sentry.withProfiler(App);
