import { providesList } from '@helpers/index';
import BaseService from '../base';
import { ContractorVehicleApiParams, ContractorVehiclesApiProps, VehiclesFilterParams } from './types';

class ContractorVehicle extends BaseService {
  getVehicles() {
    return {
      query: ({ id, ...params }: VehiclesFilterParams) => ({
        url: `contractors/${id}/vehicles`,
        params,
      }),
      providesTags: (result: ContractorVehiclesApiProps | undefined) => (
        providesList(result?.contractor_vehicles, this.tagType)
      ),
    };
  }

  addContractorVehicle() {
    return {
      query: (
        { contractorId, ...body }: ContractorVehicleApiParams & { contractorId: number },
      ) => ({
        url: `/contractors/${contractorId}/vehicles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ([{ type: this.tagType }]),
    };
  }

  deleteAllContractorVehicles() {
    return {
      query: (id: number) => ({
        url: `contractors/${id}/vehicles`,
        method: 'DELETE',
      }),
      invalidatesTags: () => (
        [{ type: this.tagType }]
      ),
    };
  }
}

export default new ContractorVehicle('contractor-vehicles', 'ContractorVehicle');
