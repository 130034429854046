/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import baseApi from '../_features/base/BaseSlice';
import accountReducer from '../_features/account/AccountSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['account'],
  blacklist: [baseApi.reducerPath],
};

const rootReducer = combineReducers({
  account: accountReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const devTools = import.meta.env.VITE_NODE_ENV !== 'production';

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
    immutableCheck: false,
  }).concat(baseApi.middleware),
  devTools,
});

export const persister = persistStore(store);
