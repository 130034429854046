/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import contractorVehicle from './contractorvehicle';
import {
  ContractorVehicleApiProps,
  ContractorVehicleApiParams,
  ContractorVehiclesApiProps,
  VehiclesFilterParams,
} from './types';

// Add Tag to the list of tags to be invalidated when the list of companies is updated
const contractorVehicleTag = baseApi.enhanceEndpoints({ addTagTypes: [contractorVehicle.tagType] });
const contractorVehicleApi = contractorVehicleTag.injectEndpoints({
  endpoints: (builder) => ({
    getContractorVehicles: builder.query<ContractorVehiclesApiProps, VehiclesFilterParams>(
      contractorVehicle.getVehicles(),
    ),
    addContractorVehicle: builder.mutation<
      ContractorVehicleApiProps,
      Partial<ContractorVehicleApiParams> & { contractorId: number }
    >(contractorVehicle.addContractorVehicle()),
    updateContractorVehicle: builder.mutation<
      void,
      Pick<ContractorVehicleApiProps, 'id'> & Partial<ContractorVehicleApiProps>
    >(contractorVehicle.updateEntity()),
    getContractorVehicle: builder.query<
      ContractorVehicleApiProps,
      number
    >(contractorVehicle.getEntity()),
    deleteAllContractorVehicles: builder.mutation<void, number>(
      contractorVehicle.deleteAllContractorVehicles(),
    ),
    deleteContractorVehicle: builder.mutation<{ success: boolean; id: number }, number>(
      contractorVehicle.deleteEntity(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetContractorVehiclesQuery,
  useAddContractorVehicleMutation,
  useUpdateContractorVehicleMutation,
  useGetContractorVehicleQuery,
  useDeleteAllContractorVehiclesMutation,
  useDeleteContractorVehicleMutation,
} = contractorVehicleApi;
