/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, useEffect } from 'react';
import { eventBus } from '@helpers/index';
import humps from 'lodash-humps-ts';
import { roleService } from '@services/index';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  assignRole,
  clearState,
  getRolePermissions,
  logOut,
} from '@features/account/AccountSlice';
import { useAppSelector, useAppDispatch } from '@hooks/index';

export type Role = {
  id: number;
  profiles: string[];
  dutyName?: string;
  enableJobEmail: boolean;
  dailyNotificationTime?: string;
  category: string;
  roleLabel: string;
};

export const useSwitchProfile = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [skip, setSkip] = useState(true);
  const { currentUser, isError } = useAppSelector((state) => state.account);
  const { useGetUserRolesQuery, useLoginRoleMutation, useSwitchRoleMutation } = roleService;
  const { data } = useGetUserRolesQuery(undefined, { skip });
  const [loginRole] = useLoginRoleMutation();
  const [switchRole] = useSwitchRoleMutation();
  const roles = humps(data) as Role[];
  const { roleId, token, passwordChange } = currentUser;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSelectProfile = useCallback(async () => {
    try {
      if (id === null) {
        message.error('Please select a role!', 2);
        return;
      }
      const response = await loginRole({ id }).unwrap();

      if (response !== undefined) {
        await dispatch(assignRole(response));
        await dispatch(getRolePermissions(response.token as string));
        setIsModalVisible(false);
      }
    } catch (e) {
      message.error('Role Selection was Unsuccessful', 2);
      dispatch(clearState());
    }
  }, [dispatch, id, loginRole]);

  const handleSwitchProfile = useCallback(
    async (switchedRoleId: number) => {
      try {
        if (switchedRoleId === null) {
          message.error('Please select a role!', 2);
          return;
        }
        const response = await switchRole({ id: switchedRoleId }).unwrap();
        if (response !== undefined) {
          await dispatch(getRolePermissions(response.token as string)).unwrap();
          dispatch(assignRole(response));
          navigate('/dashboard');
        }
      } catch (e) {
        message.error('Role Selection was Unsuccessful', 2);
        dispatch(clearState());
      }
    },
    [dispatch, switchRole],
  );

  const handleCancel = () => {
    eventBus.dispatch('logout', {});
    dispatch(logOut());
    setIsModalVisible(false);
    setSkip((prevState) => !prevState);
    navigate('/login');
  };

  const showModal = () => setIsModalVisible(true);
  useEffect(() => {
    if (token && roleId === null && passwordChange === false) {
      setSkip((prevState) => !prevState);
      showModal();
    }
  }, [roleId, token]);

  return {
    roles,
    handleCancel,
    showModal,
    handleSelectProfile,
    setId,
    isModalVisible,
    id,
    roleId,
    handleSwitchProfile,
    isError,
    currentUser,
    setSkip,
  };
};
