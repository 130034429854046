/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import contract from './contract';
import { ContractDocumentParams, DocumentApiProps, JobContract } from './types';

// Add Tag to the list of tags to be invalidated when the list of afps is updated
const contractTag = baseApi.enhanceEndpoints({ addTagTypes: [contract.tagType] });
const contractApi = contractTag.injectEndpoints({
  endpoints: (builder) => ({
    addContractDocument: builder.mutation<DocumentApiProps, ContractDocumentParams>(
      contract.createContractDocument(),
    ),
    deleteContract: builder.mutation<{ success: boolean; id: number }, number>(
      contract.delete(),
    ),
    updateContract: builder.mutation<
      JobContract,
      Partial<JobContract>>(contract.updateContract()),
  }),
  overrideExisting: false,
});

export const {
  useAddContractDocumentMutation,
  useDeleteContractMutation,
  useUpdateContractMutation,
} = contractApi;
