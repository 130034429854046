import React from 'react';
import {
  Breadcrumb, useLocation, map, useTranslation, camelCase,
} from '@lib/index';

const BreadCrumb = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const breadcrumbPath = map(location.pathname.slice(1).split('/'), (path: string) => (
    <Breadcrumb.Item key={path}>
      {Number.isNaN(Number(path)) ? t(`breadcrumb.${camelCase(path)}`) : path}
    </Breadcrumb.Item>
  ));
  return (
    <Breadcrumb className="my-4 mx-0 text-xs">
      <Breadcrumb.Item>Ksec</Breadcrumb.Item>
      {breadcrumbPath}
    </Breadcrumb>
  );
};

export default BreadCrumb;
