import React from 'react';
import { DatePicker, FormItem } from 'formik-antd';
import { FormInputValues } from './types';

const InputDateField = ({
  name,
  placeholder,
  size,
  label,
  className,
  dataCy,
}: FormInputValues & { dataCy?: string }) => (
  <FormItem name={name} label={label}>
    <DatePicker
      name={name}
      placeholder={placeholder}
      size={size}
      className={className}
      format="DD/MM/YYYY"
      data-cy={dataCy}
    />
  </FormItem>
);

InputDateField.defaultProps = {
  dataCy: '',
};

export default InputDateField;
