/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import {
  ContractorParamsProps,
  ContractorsApiProps,
  ContractorApiProps,
  ContractorSendEmailParamsProps,
  ContractorCreateParamsProps,
  JobsApiProps,
  JobParamsProps,
  ContractorAccessesTableApiProps,
  ContractorAccessTableParams,
  StoreSectionContractorParams,
  AreaSectionContractorsListParams,
  ContractorsDataApiProps,
} from './types';
import contractor from './contractor';

const contractorTag = baseApi.enhanceEndpoints({
  addTagTypes: [contractor.tagType],
});
const contractorApi = contractorTag.injectEndpoints({
  endpoints: (builder) => ({
    getContractor: builder.query<ContractorApiProps, number>(contractor.getEntity()),
    getContractorJobs:
    builder.query<JobsApiProps, JobParamsProps>(contractor.getContractorJobs()),
    getSectionContractors: builder.query<ContractorsApiProps, ContractorParamsProps>(
      contractor.list(),
    ),
    deleteContractor: builder.mutation<{ success: boolean; id: number }, number>(
      contractor.deleteEntity(),
    ),
    updateContractor: builder.mutation<
      void,
      Pick<ContractorApiProps, 'id'> & Partial<ContractorApiProps>
    >(contractor.updateEntity()),
    sendEmailContractor: builder.mutation<void, ContractorSendEmailParamsProps>(
      contractor.sendEmail(),
    ),
    getSiteMetaContractors: builder.query<ContractorApiProps[], number>(
      contractor.metaContractors(),
    ),
    getSiteContractors: builder.query<ContractorApiProps[], number>(
      contractor.contractors(),
    ),
    createContractor: builder.mutation<ContractorApiProps, Partial<ContractorCreateParamsProps>>(contractor.addEntity('sections')),
    // eslint-disable-next-line max-len
    getContractorAccessTable: builder.query<ContractorAccessesTableApiProps, ContractorAccessTableParams | undefined>(
      contractor.getContractorAccessTable(),
    ),
    deleteStoreSectionContractor: builder.mutation<void, StoreSectionContractorParams>(
      contractor.deleteStoreSectionContractor(),
    ),
    linkStoreSectionContractor: builder.mutation<
      ContractorApiProps,
      Partial<StoreSectionContractorParams>
    >(
      contractor.linkStoreSectionContractor(),
    ),
    getAreaSectionContractorsList: builder.query<
      ContractorsDataApiProps,
      AreaSectionContractorsListParams
    >(
      contractor.areaSectionContractorsList(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetSectionContractorsQuery,
  useDeleteContractorMutation,
  useUpdateContractorMutation,
  useSendEmailContractorMutation,
  useGetContractorQuery,
  useGetSiteMetaContractorsQuery,
  useGetSiteContractorsQuery,
  useCreateContractorMutation,
  useGetContractorJobsQuery,
  useGetContractorAccessTableQuery,
  useDeleteStoreSectionContractorMutation,
  useLinkStoreSectionContractorMutation,
  useGetAreaSectionContractorsListQuery,
} = contractorApi;
