/* eslint-disable class-methods-use-this */
import { providesList } from '@helpers/index';
import BaseService from '../base';
import {
  DocumentParamsProps,
  DocumentsApiProps,
  DocumentParams,
  DocumentsHistoryApiProps,
  UploadDocumentParams,
  MultipleUploadParams,
  DocumentAlertParams,
  ContractorVehicleDocumentParams,
} from './types';

class Document extends BaseService {
  delete() {
    return {
      query: (id: number) => ({
        url: `/documents/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => (
        [{ type: this.tagType }, { type: 'Invoice' }, { type: 'F30document' }, { type: 'Worker' }, { type: 'Stencil' }]
      ),
    };
  }

  createDocument() {
    return {
      query: ({ id, ...params }: DocumentParams) => ({
        url: `workers/${id}/documents`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Worker' }],
    };
  }

  createContractorVehicleDocument() {
    return {
      query: ({ id, ...params }: ContractorVehicleDocumentParams) => ({
        url: `contractor-vehicles/${id}/documents`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'ContractorVehicle' }],
    };
  }

  getDocumentations() {
    return {
      query: ({ siteId, ...params }: DocumentParamsProps) => ({
        url: `/contractors/${siteId}/documents`,
        params,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?:
        DocumentsApiProps) => providesList(result?.documents, this.tagType),
    };
  }

  getDocumentHistory() {
    return {
      query: ({ ...params }: DocumentParamsProps) => ({
        url: '/documents',
        params,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?:
        DocumentsHistoryApiProps) => providesList(result?.documents, this.tagType),
    };
  }

  getDocument() {
    return {
      query: (id: string) => ({
        url: `/documents/${id}`,
      }),
      providesTags: () => [{ type: this.tagType }],
    };
  }

  uploadDocument() {
    return {
      query: ({ contractorId, ...body }: UploadDocumentParams) => ({
        url: `contractors/${contractorId}/documents`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: this.tagType }, { type: 'Stencil' }],
    };
  }

  uploadJobDocument() {
    return {
      query: ({ jobId, ...body }: UploadDocumentParams) => ({
        url: `jobs/${jobId}/documents`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: this.tagType }, { type: 'Job' }],
    };
  }

  multipleUpload() {
    return {
      query: (body: MultipleUploadParams) => ({
        url: this.url,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Worker' }, { type: 'ContractorVehicle' }, { type: 'Job' }],
    };
  }

  updateDocument() {
    return {
      query: ({ id, ...params }: DocumentAlertParams) => ({
        url: `/documents/${id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'DocumentAlert' }, { type: 'Worker' }, { type: 'Stencil' }, { type: 'ContractorVehicle' }],
    };
  }
}

export default new Document('documents', 'Document');
