/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import {
  Navigate,
  useAppSelector,
  parseJWT,
  useLocation,
  Outlet,
  MENUS,
  some,
  useSwitchEmployers,
} from '@lib/index';
import { SCOPES } from '@config/index';
import { PrivateRouteProps, DecodedJWT } from './types';

const PrivateRoute = ({ roles, permission }: PrivateRouteProps) => {
  const { currentUser } = useAppSelector((state) => state.account);
  const location = useLocation();
  const decodedJWT: DecodedJWT = parseJWT(currentUser.kitchen as string);
  const { siteEmployers } = useSwitchEmployers();
  const f30IsPresent = some(
    siteEmployers,
    (siteEmployer) => siteEmployer.config?.f30?.includes(SCOPES.canViewRegularContractorF30Page),
  );
  if (decodedJWT && decodedJWT.exp * 1000 < Date.now()) {
    return <Navigate to="/logout" state={{ from: location }} replace />;
  }
  if (!currentUser.kitchen) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (
    permission
    && siteEmployers?.length < 2
    && !currentUser.permissions?.includes(MENUS[permission as keyof typeof MENUS])
  ) {
    return <Navigate to="/not-found" state={{ from: location }} replace />;
  }
  if (permission && siteEmployers?.length > 1 && !f30IsPresent) {
    return <Navigate to="/not-found" state={{ from: location }} replace />;
  }
  if (!roles?.includes(currentUser?.role)) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return <Outlet />;
  // return (
  //   <Route
  //     {...rest}
  //     render={(props) => {
  //       if (currentUser.kitchen === '') {
  //         return (
  //           <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  //         );
  //       }
  //       const decodedJWT: DecodedJWT = parseJWT(currentUser.kitchen as string);
  //       if ((decodedJWT && (decodedJWT.exp * 1000) < Date.now())) {
  //         return (
  //           <Redirect to={{ pathname: '/logout', state: { from: props.location } }} />
  //         );
  //       }

  //       // check if route is restricted by role
  //       if (roles && roles.indexOf(currentUser.role) === -1) {
  //         // role not authorised so redirect to home page
  //         return <Redirect to={{ pathname: '/dashboard' }} />;
  //       }

  //       // authorised so return component
  //       return <Component {...props} />;
  //     }}
  //   />
  // );
};

export default PrivateRoute;
