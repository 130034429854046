import BaseService from '../base';
import { ContractDocumentParams, JobContract } from './types';

class Contract extends BaseService {
  createContractDocument() {
    return {
      query: ({ id, ...params }: ContractDocumentParams) => ({
        url: `contracts/${id}/documents`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Worker' }, { type: 'Job' }],
    };
  }

  delete() {
    return {
      query: (id: number) => ({
        url: `/contracts/${id}`,
        method: 'delete',
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Worker' }, { type: 'Job' }],
    };
  }

  updateContract() {
    return {
      query: ({ id, ...params }: JobContract) => ({
        url: `/contracts/${id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType, id: 'LIST' }, { type: 'Worker' }, { type: 'Contract' }, { type: 'Job' }],
    };
  }
}
export default new Contract('contracts', 'Contract');
