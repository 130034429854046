/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import sectionworker from './sectionworker';
import { WorkerSection } from './types';

// Add Tag to the list of tags to be invalidated when the list of afps is updated
const sectionworkerTag = baseApi.enhanceEndpoints({ addTagTypes: [sectionworker.tagType] });
const sectionworkerApi = sectionworkerTag.injectEndpoints({
  endpoints: (builder) => ({
    deleteSectionWorker: builder.mutation<{ success: boolean; id: number }, number>(
      sectionworker.delete(),
    ),
    updateSectionWorker: builder.mutation<
    void,
    Pick<WorkerSection, 'id'> & Partial<WorkerSection>
  >(sectionworker.updateSectionWorker()),
  }),
  overrideExisting: false,
});

export const {
  useDeleteSectionWorkerMutation,
  useUpdateSectionWorkerMutation,
} = sectionworkerApi;
