import BaseService from '../base';
import { StencilDocumentsApiProps } from './types';

class Stencil extends BaseService {
  getStencilDocuments() {
    return {
      query: (contractorId: number) => ({
        url: `/contractors/${contractorId}/stencil-documents`,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: StencilDocumentsApiProps) => [{ type: this.tagType }],
    };
  }
}

export default new Stencil('stencils', 'Stencil');
