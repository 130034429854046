/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { ShiftsApiProps, ShiftApiProps } from './types';
import shift from './shift';

// Add Tag to the list of tags to be invalidated when the list of placements is updated
const shiftTag = baseApi.enhanceEndpoints({ addTagTypes: [shift.tagType] });
const shiftsApi = shiftTag.injectEndpoints({
  endpoints: (builder) => ({
    getShiftsBySite: builder.query<ShiftsApiProps, number>(
      shift.getEntitiesWithId('sites', '?pov=access_logs'),
    ),
    getShifts: builder.query<ShiftsApiProps, number>(
      shift.getEntitiesWithId('sites', '?disabled=all'),
    ),
    getJobShifts: builder.query<ShiftsApiProps, number>(
      shift.getEntitiesWithId('sites'),
    ),
    getShift: builder.query<ShiftApiProps, number>(shift.getEntity()),
    addShift: builder.mutation<ShiftApiProps, Partial<ShiftApiProps>>(shift.addEntity('sites')),
    updateShift: builder.mutation<void, Pick<ShiftApiProps, 'id'> & Partial<ShiftApiProps>>(shift.updateEntity()),
    deleteShift: builder.mutation<{ success: boolean; id: number }, number>(shift.deleteEntity()),
  }),
  overrideExisting: false,
});

export const {
  useGetShiftsBySiteQuery, useGetShiftsQuery,
  useAddShiftMutation, useGetShiftQuery,
  useUpdateShiftMutation, useDeleteShiftMutation,
  useGetJobShiftsQuery,
} = shiftsApi;
