/* eslint-disable camelcase */
// eslint-disable-next-line import/prefer-default-export
export enum ROLE {
  admin = 'admin',
  administrator_section = 'administrator_section',
  area_section = 'area_section',
  store_section = 'store_section',
  staffing_contractor = 'staffing_contractor',
  regular_contractor = 'regular_contractor',
  corp_site = 'corp_site',
}
