import * as React from 'react';
import {
  SubmitButton, Form, FormItem, Formik, FormikHelpers, Link, message,
  Row, Col, Typography, Divider, UserOutlined, LeftCircleOutlined,
  InputPasswordField, useTranslation, authenticationService,
  useParams,
} from '@lib/index';
import { ResetFormWithTokenProps } from '../types';
import { passwordResetFormWithTokenValidation } from '../schema/AuthFormValidation';
import { passwordResetFormWithTokenInitialValues } from '../schema/AuthDefaultValue';

const PasswordResetFormWithToken = () => {
  const { token } = useParams<Record<string, string>>();
  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const { changePasswordWithToken } = authenticationService;

  const onSubmit = async (
    values: ResetFormWithTokenProps,
    { setSubmitting, resetForm }: FormikHelpers<ResetFormWithTokenProps>,
  ) => {
    try {
      const response = await changePasswordWithToken({
        ...values,
        resetPasswordToken: token as string,
      });
      if (!response.ok) {
        message.error(t('login.passwordResetError'));
        return;
      }
      message.success(t('login.passwordResetSuccess'));
      setSubmitting(false);
      resetForm();
    } catch (error) {
      message.error(t('login.passwordResetError'));
      setSubmitting(false);
    }
  };
  return (
    <div className="bg-login-bg bg-no-repeat bg-cover h-full bg-center p-24 shadow-inner">
      <Row align="middle" justify="center" className="mt-0">
        <Col xs={12} sm={12} md={12} lg={12} xl={7} className="mt-0">
          <Link to="/login" className="text-white text-3xl">
            <LeftCircleOutlined className="text-white text-4xl pr-8" />
            {t('login.backToLogin')}
          </Link>
          <Formik
            enableReinitialize
            initialValues={passwordResetFormWithTokenInitialValues}
            onSubmit={onSubmit}
            validationSchema={passwordResetFormWithTokenValidation}
          >
            <div className="p-12 mt-4 h-full w-full bg-white">
              <Title type="secondary" className="text-center" level={4}>
                {t('login.passwordRecovery')}
              </Title>
              <Form name="normal_login" className="login-form">
                <InputPasswordField
                  name="password"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t('login.password')}
                  size="large"
                />
                <InputPasswordField
                  name="passwordConfirmation"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t('login.confirmPassword')}
                  size="large"
                />
                <FormItem name="button">
                  <SubmitButton block size="large">
                    {t('login.send')}
                  </SubmitButton>
                </FormItem>
              </Form>
              <Divider plain>{t('login.note')}</Divider>
              <Text className="text-sm">
                {t('login.aStore')}
              </Text>
            </div>
          </Formik>
          <div className="bg-logo-bg bg-no-repeat bg-center h-60 bg-auto" />
        </Col>
      </Row>
    </div>
  );
};
export default PasswordResetFormWithToken;
