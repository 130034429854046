import React from 'react';
import Auth0Login from './Auth0Login';
import LoginForm from './LoginForm';

const Index = () => {
  const auth0Enabled = import.meta.env.VITE_AUTH0_ENABLED === 'true';

  return (
    auth0Enabled ? <Auth0Login /> : <LoginForm />
  );
};

export default Index;
