// eslint-disable-next-line react/jsx-props-no-spreading
import React from 'react';
import { Input, FormItem } from 'formik-antd';
import { FormInputValues } from './types';

const InputPasswordField = ({
  name,
  placeholder,
  prefix,
  size,
  label,
  disabled,
  ...rest
}: FormInputValues) => (
  <FormItem name={name} label={label}>
    <Input.Password
      name={name}
      prefix={prefix}
      placeholder={placeholder}
      size={size}
      disabled={disabled}
      {...rest}
    />
  </FormItem>
);

export default InputPasswordField;
