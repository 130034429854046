/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import baseApi from '@features/base/BaseSlice';
import { DocumentAlertsApiProps, DocumentAlertsParamsProps, PendingDocumentAlertsApiProps } from './types';
import documentAlert from './documentalert';

const documentAlertTag = baseApi.enhanceEndpoints({ addTagTypes: [documentAlert.tagType] });
const documentAlertApi = documentAlertTag.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentAlerts:
      builder.query<DocumentAlertsApiProps, DocumentAlertsParamsProps>(documentAlert.getDocumentAlerts()),
    getPendingAlerts: builder.query<PendingDocumentAlertsApiProps, void>(documentAlert.getPendingAlerts()),
  }),
  overrideExisting: false,
});

export const {
  useGetDocumentAlertsQuery,
  useGetPendingAlertsQuery,
} = documentAlertApi;
