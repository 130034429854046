/* eslint-disable import/prefer-default-export */
import {
  humps,
  siteService,
  useAppSelector,
} from '@lib/index';
import { EmployerDataProps } from './types';

export const useSwitchEmployers = () => {
  const { currentUser } = useAppSelector((state) => state.account);
  const { contractorId, siteId } = currentUser;
  const { useGetEmployersQuery } = siteService;
  const { data: employers, isLoading: siteEmployersLoading } = useGetEmployersQuery(
    siteId as number,
  );
  const siteEmployers = humps(employers) as EmployerDataProps[];
  const hasEmployers = siteEmployers?.length > 1;
  return {
    siteEmployers,
    contractorId,
    hasEmployers,
    siteEmployersLoading,
  };
};
