import { providesList } from '@helpers/index';
import BaseService from '../base';
import {
  AccessesPerformanceApiProps,
  AccessParamsProps,
  AccessesApiProps,
  AccessPerformanceParams,
  AccessStaffingTableParams,
  AccessesStaffingTableApiProps,
  AccessByDocumentParams,
  SectionAccessParamsProps,
  AccessContractorPerformanceParams,
  AccessAreaSectionPerformanceParams,
} from './types';

class Access extends BaseService {
  getAccessPerformance() {
    return {
      query: ({ siteId, params }: AccessPerformanceParams) => ({
        url: `sites/${siteId}/access_performance`,
        params,
      }),
      providesTags: (result?: AccessesPerformanceApiProps) => providesList(
        result?.accessPerformance?.daily,
        this.tagType,
      ),
    };
  }

  getContractorAccessPerformance() {
    return {
      query: ({ contractorId, params }: AccessContractorPerformanceParams) => ({
        url: `contractors/${contractorId}/access_performance`,
        params,
      }),
      providesTags: (result?: AccessesPerformanceApiProps) => providesList(
        result?.accessPerformance?.daily,
        this.tagType,
      ),
    };
  }

  getAreaSectionAccessPerformance() {
    return {
      query: ({ sectionId, params }: AccessAreaSectionPerformanceParams) => ({
        url: `sections/${sectionId}/access_performance`,
        params,
      }),
      providesTags: (result?: AccessesPerformanceApiProps) => providesList(
        result?.accessPerformance?.daily,
        this.tagType,
      ),
    };
  }

  getAccesses() {
    return {
      query: ({ siteId, ...params }: AccessParamsProps) => ({
        url: `sites/${siteId}/logs`,
        params,
      }),

      providesTags: (result: AccessesApiProps | undefined) => (
        providesList(result?.accesses, this.tagType)
      ),
    };
  }

  getSectionAccesses() {
    return {
      query: ({ sectionId, ...params }: SectionAccessParamsProps) => ({
        url: `sections/${sectionId}/logs`,
        params,
      }),

      providesTags: (result: AccessesApiProps | undefined) => (
        providesList(result?.accesses, this.tagType)
      ),
    };
  }

  getAccessStaffingTable() {
    return {
      query: ({ contractorId, ...params }: AccessStaffingTableParams) => ({
        url: `contractors/${contractorId}/stats/access`,
        params,
      }),
      providesTags: (result?: AccessesStaffingTableApiProps) => providesList(
        result?.rows,
        this.tagType,
      ),
    };
  }

  getAccessByDocuments() {
    return {
      query: ({ contractorId, ...params }: AccessByDocumentParams) => ({
        url: `contractors/${contractorId}/stats/documents`,
        params,
      }),
      providesTags: [{ type: this.tagType, id: 'LIST' }],
    };
  }
}

export default new Access('accesses', 'Access');
