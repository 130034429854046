import React, { useRef } from 'react';
import {
  SubmitButton, Form, FormItem, Formik, FormikHelpers, Link, message,
  Row, Col, Typography, Divider, UserOutlined, LeftCircleOutlined,
  InputTextField, useTranslation, authenticationService,
} from '@lib/index';
import ReCAPTCHA from 'react-google-recaptcha';
import { ResetFormProps } from '../types';
import { passwordResetFormValidation } from '../schema/AuthFormValidation';
import { passwordChangeInitialValues } from '../schema/AuthDefaultValue';

const PasswordResetForm = () => {
  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const { forgotPassword } = authenticationService;
  const onSubmit = async (
    values: ResetFormProps,
    { setSubmitting, resetForm }: FormikHelpers<ResetFormProps>,
  ) => {
    try {
      const reCaptchaToken = reCaptchaRef.current?.getValue();

      if (!reCaptchaToken) {
        message.warning(t('login.reCaptcha'), 5);
        setSubmitting(false);
        return;
      }
      await forgotPassword({ email: values.email, reCaptcha: reCaptchaToken as string });
      message.success(t('login.resetPasswordSuccess'));
      setSubmitting(false);
      resetForm();
      reCaptchaRef.current?.reset();
    } catch {
      message.success(t('login.passwordResetError'));
    }
  };
  return (
    <div className="bg-login-bg bg-no-repeat bg-cover h-full bg-center p-24 shadow-inner">
      <Row align="middle" justify="center" className="mt-0">
        <Col xs={12} sm={12} md={12} lg={12} xl={7} className="mt-0">
          <Link to="/login" className="text-white text-3xl">
            <LeftCircleOutlined className="text-white text-4xl pr-8" />
            {t('login.backToLogin')}
          </Link>
          <Formik
            enableReinitialize
            initialValues={passwordChangeInitialValues}
            onSubmit={onSubmit}
            validationSchema={passwordResetFormValidation}
          >
            <div className="p-12 mt-4 h-full w-full bg-white">
              <Title type="secondary" className="text-center" level={4}>
                {t('login.passwordRecovery')}
              </Title>
              <Form name="normal_login" className="login-form">
                <InputTextField
                  name="email"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t('login.email')}
                  size="large"
                />
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY as string}
                />
                <FormItem name="button">
                  <SubmitButton block size="large">
                    {t('login.send')}
                  </SubmitButton>
                </FormItem>
              </Form>
              <Divider plain>{t('login.note')}</Divider>
              <Text className="text-sm">
                {t('login.aStore')}
              </Text>
            </div>
          </Formik>
          <div className="bg-logo-bg bg-no-repeat bg-center h-60 bg-auto" />
        </Col>
      </Row>
    </div>
  );
};
export default PasswordResetForm;
