/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { MutualsApiProps } from './types';
import mutual from './mutual';

// Add Tag to the list of tags to be invalidated when the list of mutuals is updated
const mutualTag = baseApi.enhanceEndpoints({ addTagTypes: [mutual.tagType] });
const mutualAPi = mutualTag.injectEndpoints({
  endpoints: (builder) => ({
    getMutuals: builder.query<MutualsApiProps, void>(mutual.getEntities()),
  }),
  overrideExisting: false,
});

export const { useGetMutualsQuery } = mutualAPi;
