import BaseService from '../base';
import {
  F30Params,
  F30WorkersApiProps,
  F30WorkersParams,
  UploadF30Params,
} from './types';

class F30 extends BaseService {
  getF30DocumentsByMonth() {
    return {
      query: ({ contractorId, month }: F30Params) => ({
        url: `contractors/${contractorId}/f30/${month}`,
      }),
      providesTags: [{ type: this.tagType }],
    };
  }

  getF30Workers() {
    return {
      query: ({ contractorId, month, params }: F30WorkersParams) => ({
        url: `contractors/${contractorId}/f30/${month}/workers`,
        params,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result?: F30WorkersApiProps) => [{ type: this.tagType }],
    };
  }

  uploadF30() {
    return {
      query: ({ contractorId, period, ...params }: UploadF30Params) => ({
        url: `contractors/${contractorId}/f30/${period}/documents`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType }, { type: 'Stencil' }],
    };
  }

  uploadJustification() {
    return {
      query: ({ contractorId, period, ...params }: UploadF30Params) => ({
        url: `contractors/${contractorId}/f30/${period}/justifications`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [{ type: this.tagType }, { type: 'Stencil' }],
    };
  }

  deleteJustification() {
    return {
      query: ({
        contractorId,
        period,
        id,
        ...body
      }: UploadF30Params) => ({
        url: `contractors/${contractorId}/f30/${period}/justifications/${id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [{ type: this.tagType }, { type: 'Stencil' }],
    };
  }
}

export default new F30('F30documents', 'F30document');
