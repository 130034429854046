/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { UserReportApiProps } from './types';
import userReport from './userreport';

// Add Tag to the list of tags to be invalidated when the list of countries is updated
const userReportTag = baseApi.enhanceEndpoints({
  addTagTypes: [userReport.tagType],
});
const userReportApi = userReportTag.injectEndpoints({
  endpoints: (builder) => ({
    sendReport: builder.mutation<void, Partial<UserReportApiProps>>(
      userReport.sendReport(),
    ),
  }),
  overrideExisting: false,
});

export const { useSendReportMutation } = userReportApi;
