import { Yup, i18next } from '@lib/index';

const { t } = i18next;

export const loginFormValidation = Yup.object({
  email: Yup.string().email(t('login.emailInvalid')).required(t('login.emailRequired')),
  password: Yup.string().min(6, t('login.passwordMinLength')).required(t('login.passwordRequired')),
});

export const passwordResetFormValidation = Yup.object({
  email: Yup.string().email(t('login.emailInvalid')).required(t('login.emailRequired')),
});

export const passwordResetFormWithTokenValidation = Yup.object({
  password: Yup.string().min(6, t('login.passwordMinLength')).required(t('login.passwordRequired')),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], t('login.passwordMatch')).required(t('login.passwordConfirmationRequired')),
});
