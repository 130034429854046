/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { UploadApiAttachment } from './types';
import upload from './upload';

// Add Tag to the list of tags to be invalidated when the list of countries is updated
const uploadTag = baseApi.enhanceEndpoints({ addTagTypes: [upload.tagType] });
const uploadApi = uploadTag.injectEndpoints({
  endpoints: (builder) => ({
    createUpload: builder.mutation<UploadApiAttachment, FormData>(upload.addFile()),
  }),
  overrideExisting: false,
});

export const { useCreateUploadMutation } = uploadApi;
