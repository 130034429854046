/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { createHumps } from 'lodash-humps-ts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toCamelCase = (data: any) => _.mapKeys(data, (value, key) => _.camelCase(key));

export const toSnakeCase = (data: Record<string, unknown>) => _.mapKeys(data, (value, key) => _.snakeCase(key));
export const toSentenceCase = (
  str:
    | undefined
    | string
    | 'deactivated'
    | 'approved'
    | 'incomplete'
    | 'rejected'
    | 'loaded'
    | 'notLoaded'
    | 'expired'
    | 'digitalReview',
) => _.upperFirst(_.lowerCase(str));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function camelcaseObjectDeep(value: any): any { // #TODO: use lodash.hump
  if (Array.isArray(value)) {
    return value.map(camelcaseObjectDeep);
  }

  if (value && typeof value === 'object' && value.constructor === Object) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    const keys = Object.keys(value);
    const len = keys.length;

    for (let i = 0; i < len; i += 1) {
      obj[_.camelCase(keys[i])] = camelcaseObjectDeep(value[keys[i]]);
    }

    return obj;
  }

  return value;
}

export const toSnakeCaseNested = createHumps(_.snakeCase);
