/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */

import baseApi from '@features/base/BaseSlice';
import {
  AttachmentsApiProps,
  AttachmentApiProps,
  CreateAttachmentApiParams,
} from './types';
import attachment from './attachment';

// Add Tag to the list of tags to be invalidated when the list of attachments is updated
const attachmentTag = baseApi.enhanceEndpoints({ addTagTypes: [attachment.tagType] });
const attachmentApi = attachmentTag.injectEndpoints({
  endpoints: (builder) => ({
    getAttachments: builder.query<AttachmentsApiProps, number>(attachment.getEntitiesWithId('jobs')),
    createAttachment: builder.mutation<AttachmentApiProps, Partial<CreateAttachmentApiParams>>(
      attachment.addEntity('jobs'),
    ),
    deleteAttachment: builder.mutation<{ success: boolean; id: number }, number>(
      attachment.deleteEntity(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetAttachmentsQuery,
  useCreateAttachmentMutation,
  useDeleteAttachmentMutation,
} = attachmentApi;
