import React from 'react';
import { Modal, Typography, Button } from 'antd';

const ErrorFallback = ({ error, componentStack, resetError }: {
  error: Error;
  resetError: (...args: Array<unknown>) => void;
  componentStack: string;
}) => {
  const { Title, Paragraph } = Typography;
  return (
    <Modal
      visible={!!error}
      footer={(
        <Button type="primary" onClick={resetError}>Try again</Button>

      )}
    >
      <Typography>
        <Title level={5}>Something went wrong:</Title>
        <Paragraph>{error.message}</Paragraph>
      </Typography>
    </Modal>
  );
};
export default ErrorFallback;
