/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useMemo, useState } from 'react';
import _, { snakeCase, truncate } from 'lodash';
import { Skeleton, Tooltip } from 'antd';
import {
  calcPercent, displayRow, formatDayWithUtc, isWeekend, queryToURL,
} from '@helpers/index';
import { useSetPlacementsShifts, useSetDate } from '@hooks/index';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { createHumps } from 'lodash-humps-ts';
import {
  AccessDataProps,
  FiltersProps, RowSelectorProps,
} from './types';
import '@styles/staffingTable.less';

const StaffingTable = ({
  source, loading, shiftId, contractorId, jobId,
}:
  {
    source: AccessDataProps | undefined, loading: boolean, shiftId: number | null;
    jobId?: number; contractorId?: number;
  }) => {
  const [filters, setFilters] = useState<FiltersProps>({ shift: null });
  const [clickedRows, setClickedRows] = useState<number[]>([]);
  const { dates } = useSetDate(source);
  const { placements, shifts } = useSetPlacementsShifts(source);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showPlacement = (placementId: number) => {
    if (!filters.shift) return true;
    const placement = _.find(placements, { id: placementId });
    return _.some(placement?.shifts, { id: filters.shift });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filterShift = useMemo(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      shift: shiftId,
    }));
  }, [shiftId]);
  const getCellValues = ({ placementId, shiftId, day }: {
    placementId?: number,
    shiftId?: number,
    day?: number,
  }) => {
    const rowSelector: RowSelectorProps = {};
    if (filters.shift) shiftId = filters.shift;
    if (placementId) rowSelector.placement_id = placementId;
    if (shiftId) rowSelector.job_shift_id = shiftId;
    let rows;
    rows = source?.rows;
    if (!_.isEmpty(rowSelector)) rows = _.filter(source?.rows, rowSelector);
    let points = _.flatten(_.map(rows, 'points'));
    if (day) points = points.filter((point) => Date.parse(point[0]) === +day);
    return {
      expected: _.sumBy(points, '1'),
      real: _.sumBy(points, '2'),
      incomplete: _.sumBy(points, '3'),
    };
  };

  const handleInsertPlacement = (placementId: number) => {
    displayRow(placementId, clickedRows)
      ? setClickedRows(_.without(clickedRows, placementId))
      : setClickedRows((prevState) => [...prevState, placementId]);
  };
  const displayDateHeader = _.map(dates, (date) => (
    <th
      key={date}
      className={`diagonal-cell-hv ${isWeekend(date) ? 'weekend' : ''}`}
    >
      {formatDayWithUtc(date, 'dd DD/MM')}
    </th>
  ));
  const buildLogLinks = (linkParams:
    { day?: number; placementId?: number; shiftId?: number }) => {
    const { day } = linkParams;
    const date = formatDayWithUtc(day as number, 'YYYY/MM/DD');
    const contractorParams = {
      ...linkParams,
      source: 'contractor_worker',
      type: 'entry',
      result: 'authorized',
      since: date,
      until: date,
      day: '',
      contractorId,
    };
    const jobParams = {
      ...linkParams,
      type: 'entry',
      result: 'authorized',
      since: date,
      until: date,
      day: '',
      jobId,
    };
    const convertToSnake = createHumps(snakeCase);
    const params = jobId !== 0 ? jobParams : contractorParams;
    const accessUrl = queryToURL(convertToSnake({ ...params, tab: 'accesses' }));
    navigate({
      pathname: '/workers-accesses',
      search: accessUrl,
    });
    jobId === 0 ? window.location.reload() : null;
  };

  const displayPlacements = _.map(placements, (placement) => showPlacement(placement.id) && (
    <React.Fragment key={placement.id}>
      <tr>
        <th className="fixed-column clickable diagonal-cell-hh" onClick={() => handleInsertPlacement(placement.id)}>
          <span className="fixed-column-label">
            <Tooltip title={placement.name}>
              <span>{truncate(placement.name, { length: 25 })}</span>
            </Tooltip>
          </span>
        </th>
        <td className="hidden-column-name">&nbsp;</td>
        {_.map(dates, (date) => (
          <td
            key={date}
            className={`diagonal-cell ${isWeekend(date) ? 'weekend' : ''} clickable`}
            onClick={() => buildLogLinks({
              day: date,
              placementId: placement.id,
              shiftId: filters.shift as number,
            })}
          >
            <span className="relative">
              {getCellValues({ placementId: placement.id, day: date }).real}
            </span>
            <span className="total">
              {getCellValues({ placementId: placement.id, day: date }).expected}
            </span>
          </td>
        ))}
        <td className="diagonal-cell fixed-right-column">
          <span className="relative-right-column">{getCellValues({ placementId: placement.id }).real}</span>
          <span className="total">{getCellValues({ placementId: placement.id }).expected}</span>
        </td>
      </tr>
      {displayRow(placement.id, clickedRows) && !filters.shift
        && _.map(placement.shifts, (shift) => (
          <tr key={shift.id}>
            <th className="fixed-column normal">
              <span className="fixed-column-label">
                {shift.name}
              </span>
            </th>
            <td className="hidden-column-name">&nbsp;</td>
            {_.map(dates, (date) => {
              const { real, expected } = getCellValues({
                placementId: placement.id,
                shiftId: shift.id,
                day: date,
              });
              return (
                <td
                  className={`diagonal-cell ${isWeekend(date) ? 'weekend' : ''} clickable variation`}
                  onClick={() => buildLogLinks({
                    day: date,
                    placementId: placement.id,
                    shiftId: shift.id,
                  })}
                  key={date}
                >
                  <span className="relative">{real}</span>
                  <span className="total">{expected}</span>
                </td>

              );
            })}
            <td className="diagonal-cell variation fixed-right-column">
              <span className="relative-right-column">{getCellValues({ placementId: placement.id, shiftId: shift.id }).real}</span>
              <span className="total">{getCellValues({ placementId: placement.id, shiftId: shift.id }).expected}</span>
            </td>
          </tr>
        ))}
    </React.Fragment>
  ));

  const displayIncomplete = _.map(dates, (date) => (
    <td className={`diagonal-cell incomplete ${isWeekend(date) ? 'weekend' : ''}`} key={date}>
      <span className="relative">{getCellValues({ day: date }).incomplete}</span>
      <span className="total">{getCellValues({ day: date }).real}</span>
    </td>
  ));

  const displayTotal = _.map(dates, (date) => (
    <td
      className={`diagonal-cell clickable ${isWeekend(date) ? 'weekend' : ''}`}
      key={date}
      onClick={() => buildLogLinks({ day: date })}
    >
      <span className="relative">{getCellValues({ day: date }).real}</span>
      <span className="total">{getCellValues({ day: date }).expected}</span>
    </td>
  ));

  const displayShift = displayRow(-100, clickedRows) && !filters.shift && (
    _.map(shifts, (shift) => {
      const { real, expected } = getCellValues({ shiftId: shift.id });
      return (
        <tr key={shift.id}>
          <th className="fixed-column normal">{shift.name}</th>
          <td className="hidden-column-name">&nbsp;</td>
          {_.map(dates, (date) => {
            const { real, expected } = getCellValues({ shiftId: shift.id, day: date });
            return (
              <td
                className={`diagonal-cell clickable variation ${isWeekend(date) ? 'weekend' : ''}`}
                key={date}
                onClick={() => buildLogLinks({ day: date, shiftId: shift.id })}
              >
                <span className="relative">{real}</span>
                <span className="total">{expected}</span>
              </td>
            );
          })}

          <td className="diagonal-cell fixed-right-column">
            <span className="relative-right-column">{real}</span>
            <span className="total">{expected}</span>
          </td>
          <td className="hidden-right-column" />
        </tr>
      );
    })
  );

  const displayTotalPercent = _.map(dates, (date) => (
    <td className={`total-cell ${isWeekend(date) ? 'weekend' : ''}`} key={date}>
      <span>{`${calcPercent(getCellValues({ day: date }))}%`}</span>
    </td>
  ));

  const displayShiftPercent = displayRow(-200, clickedRows) && !filters.shift && (
    _.map(shifts, (shift) => (
      <tr key={shift.id}>
        <th className="fixed-column normal">{shift.name}</th>
        <td className="hidden-column-name">&nbsp;</td>
        {_.map(dates, (date) => (
          <td
            className={`total-cell clickable ${isWeekend(date) ? 'weekend' : ''}`}
            key={date}
            onClick={() => buildLogLinks({ day: date, shiftId: shift.id })}
          >
            <span>{calcPercent(getCellValues({ shiftId: shift.id, day: date }))}</span>
          </td>
        ))}

        <td className="total-cell fixed-right-column">
          <span>{calcPercent(getCellValues({ shiftId: shift.id }))}</span>
        </td>
        <td className="hidden-right-column" />
      </tr>
    ))
  );

  return (
    <Skeleton loading={loading}>
      <div className="fixed-table-wrapper">
        <div className="fixed-column-table ks-table-bordered">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="fixed-column fixed-column-header">
                  <span style={{ position: 'relative', top: '4px' }}>
                    {t('workerAccess.placement')}
                  </span>
                  <span style={{ position: 'relative', top: '2px', left: '5px' }}>
                    \
                  </span>
                  <span
                    style={{ position: 'relative', left: '10px', top: '4px' }}
                  >
                    {t('workerAccess.day')}
                  </span>
                </th>
                <th className="hidden-column"> </th>
                {displayDateHeader}
                <th className="fixed-right-column fixed-right-column-header">
                  {t('workerAccess.total')}
                </th>
                <th className="hidden-right-column"> </th>
              </tr>
            </thead>
            <tbody>
              {displayPlacements}
            </tbody>
            <tfoot>
              <tr><td colSpan={100} className="cell-separator" /></tr>
              <tr>
                <th className="fixed-column diagonal-cell-hh">{t('workerAccess.noCheckOut')}</th>
                <td>&nbsp;</td>
                {displayIncomplete}
                <td className="diagonal-cell fixed-right-column">
                  <span className="relative-right-column">{getCellValues({}).incomplete}</span>
                  <span className="total">{getCellValues({}).real}</span>
                </td>
                <td className="hidden-right-column" />
              </tr>
              <tr>
                <th className="fixed-column clickable diagonal-cell-hh" onClick={() => handleInsertPlacement(-100)}>{t('workerAccess.total')}</th>
                <td>&nbsp;</td>
                {displayTotal}
                <td className="diagonal-cell fixed-right-column">
                  <span className="relative-right-column">{getCellValues({}).real}</span>
                  <span className="total">{getCellValues({}).expected}</span>
                </td>
                <td className="hidden-right-column" />
              </tr>
              {displayShift}
              <tr>
                <th onClick={() => handleInsertPlacement(-200)} className="fixed-column clickable diagonal-cell-hh">{t('workerAccess.totalAccess')}</th>
                <td>&nbsp;</td>
                {displayTotalPercent}
                <td className="total-cell fixed-right-column">
                  <span>{`${calcPercent(getCellValues({}))}%`}</span>
                </td>
                <td className="hidden-right-column" />
              </tr>
              {displayShiftPercent}
            </tfoot>
          </table>
        </div>
      </div>
    </Skeleton>
  );
};
StaffingTable.defaultProps = {
  jobId: 0,
  contractorId: 0,
};

export default StaffingTable;
