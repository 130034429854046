/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import baseApi from '@features/base/BaseSlice';
import { PlacementsApiProps, PlacementsBySiteApiProps, PlacementApiProps } from './types';
import placement from './placement';

// Add Tag to the list of tags to be invalidated when the list of placements is updated
const placementTag = baseApi.enhanceEndpoints({ addTagTypes: [placement.tagType] });
const placementApi = placementTag.injectEndpoints({
  endpoints: (builder) => ({
    getPlacementsByContractor: builder.query<PlacementsApiProps, number>(
      placement.getEntitiesWithId('contractors', '?active=true&disabled=all'),
    ),
    getPlacementsBySite: builder.query<PlacementsBySiteApiProps, number>(
      placement.getEntitiesWithId('sites', '?pov=access_logs'),
    ),
    getPlacements: builder.query<PlacementsApiProps, number>(
      placement.getEntitiesWithId('sites', '?disabled=all'),
    ),
    getJobPlacements: builder.query<PlacementsApiProps, number>(
      placement.getEntitiesWithId('sites', '?staffing=true'),
    ),
    getPlacement: builder.query<PlacementApiProps, number>(placement.getEntity()),
    addPlacement: builder.mutation<PlacementApiProps, Partial<PlacementApiProps>>(placement.addEntity('sites')),
    updatePlacement: builder.mutation<void, Pick<PlacementApiProps, 'id'> & Partial<PlacementApiProps>>(placement.updateEntity()),
    deletePlacement: builder.mutation<{ success: boolean; id: number }, number>(
      placement.deleteEntity(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetPlacementsByContractorQuery,
  useGetPlacementsBySiteQuery,
  useAddPlacementMutation,
  useDeletePlacementMutation,
  useGetPlacementQuery,
  useUpdatePlacementMutation,
  useGetPlacementsQuery,
  useGetJobPlacementsQuery,
} = placementApi;
