/* eslint-disable import/prefer-default-export */
export function decodeQueryChar(urlData: string) {
  // decodes a URI component for @ char
  return decodeURIComponent(urlData).replace(/\\%40/g, '@').replace(/\+/g, '%20');
}

export function queryToURL(values: { [key: string]: string | number }) {
  const keys = Object.keys(values);
  const searchParams = new URLSearchParams();
  keys.forEach((key) => values[key] && searchParams.append(key, values[key].toString()));
  return decodeQueryChar(searchParams.toString());
}
