/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import baseApi from '@features/base/BaseSlice';
import metaContractor from './metacontractor';
import {
  CorpSiteContractorParamsProps,
  CorpSiteContractorsApiProps,
  CorpSiteMetaContractorParamsProps,
  CorpSiteMetaContractorApiProps,
  MetaContractorApiProps,
} from './types';

const metaContractorTag = baseApi.enhanceEndpoints({
  addTagTypes: [metaContractor.tagType],
});
const metaContractorApi = metaContractorTag.injectEndpoints({
  endpoints: (builder) => ({
    createMetaContractor: builder.mutation<
      CorpSiteMetaContractorApiProps,
      Partial<CorpSiteMetaContractorParamsProps>
    >(
      metaContractor.addEntity('sites'),
    ),
    updateMetaContractor: builder.mutation<
      void,
      Pick<MetaContractorApiProps, 'id'> & Partial<MetaContractorApiProps>
    >(metaContractor.updateMetaContractor()),
    getMetaContractor: builder.query<CorpSiteMetaContractorApiProps, number>(
      metaContractor.getEntity(),
    ),
    getCorpSiteMetaContractors: builder.query<
      CorpSiteContractorsApiProps,
      Partial<CorpSiteContractorParamsProps>
    >(metaContractor.getCorpSiteMetaContractors()),
    deleteMetaContractor: builder.mutation<{ success: boolean; id: number }, number>(
      metaContractor.deleteEntity(),
    ),
  }),
  overrideExisting: false,
});

export const {
  useCreateMetaContractorMutation,
  useUpdateMetaContractorMutation,
  useGetCorpSiteMetaContractorsQuery,
  useDeleteMetaContractorMutation,
  useGetMetaContractorQuery,
} = metaContractorApi;
